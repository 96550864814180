import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ptBR } from '@mui/material/locale';

import getHistory from '@giro/shared-utils/getHistory.util';

import themeConstants from '@giro/shared-constants/theme.constant';
import { PALETTE_PRIMARY, PALLET_SECONDARY } from './constants/colors.constant';

import { useStore } from '@giro-pdv-whitelabel/store';

import reportWebVitals from './reportWebVitals';

import Routes from './routes';

import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-simple-keyboard/build/css/index.css';

const App = () => {
  const store = useStore();
  const theme = createTheme(
    {
      ...({
        ...themeConstants,
        typography: {
          fontFamily: 'CenturyGothic',
        },
        palette: {
          ...themeConstants.palette,
          primary: PALETTE_PRIMARY,
          secondary: PALLET_SECONDARY,
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1400,
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
              },
            },
            variants: [
              {
                props: { variant: 'contained', color: 'primary' },
                style: {
                  color: 'white',
                },
              },
            ],
          },
        },
      } as any),
    },
    ptBR
  );

  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <SnackbarProvider>
            <Router history={getHistory()}>
              <Routes />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
