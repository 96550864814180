import { Switch, Route } from 'react-router-dom';

import useProtectedByAuth from '@giro-pdv-whitelabel/hooks/useProtectedByAuth.hook';

import ConfigurationScreen from './screens/Configuration.screen';

import AppLayout from '@giro-pdv-whitelabel/layouts/App.layout';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

const ConfigurationModule = () => {
  useProtectedByAuth();

  return (
    <AppLayout>
      <Switch>
        <Route
          exact
          path={ROUTES.CONFIGURATIONS}
          component={ConfigurationScreen}
        />
      </Switch>
    </AppLayout>
  );
};

export default ConfigurationModule;
