import { takeLatest, call, put, delay } from 'redux-saga/effects';

import toaster from '@giro/shared-store/toaster';
import router from '@giro/shared-store/router';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import { ACTION_TYPES } from './changePassword.constant';
import * as actions from './changePassword.action';

function* handleServicePost(action) {
  const { payload } = action;

  yield put(actions.fetchStart({}));

  yield delay(2000);

  yield put(actions.fetchEnd({}));
  yield put(
    toaster.action.show({
      message: 'Senha trocada com sucesso!',
      variant: 'success',
    })
  );
  yield put(router.action.push(ROUTES.LOGIN.ROOT));
}

function* watch() {
  yield takeLatest(ACTION_TYPES.SERVICE.POST, handleServicePost);
}

export default {
  watch,
};
