import React from 'react';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { Field } from 'formik';

import TextFieldLabelKeyboardComponent from '@giro-pdv-whitelabel/components/TextFieldLabelKeyboard.component';

import FieldLabelFormik from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';

import messages from '@giro/shared-constants/messagesSchema.constant';

export const validationSchema = Yup.object({
  placa: Yup.string()
    .required(messages.REQUIRED)
    .min(7, messages.MIN)
    .max(7, messages.MAX)
    .matches(
      /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/,
      'Você precisa digitar uma placa válida'
    ),
  renavam: Yup.string().required(messages.REQUIRED),
});

const FormPlacaRenavamComponent = (props) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" {...props} gap={4}>
      <TextFieldLabelKeyboardComponent
        name="placa"
        label="Placa"
        size="small"
      />

      {/* <Field name="placa">
        {({
          field,
          form: { setFieldValue, setFieldTouched },
          meta: { error, touched },
        }) => (
          <TextFieldLabelComponent
            size="small"
            fullWidth
            label="Placa"
            variant="outlined"
            disabled={props?.disabled}
            value={field.value}
            error={touched && !!error}
            helperText={touched && !!error && error}
            onBlur={() => {
              setFieldTouched(field.name, true);
            }}
            onChange={({ target: { value } }) =>
              setFieldValue(field.name, String(value).toUpperCase())
            }
          />
        )}
      </Field> */}
      <TextFieldLabelKeyboardComponent
        name="renavam"
        label="Renavam"
        size="small"
      />
    </Box>
  );
};

export default FormPlacaRenavamComponent;
