import React from 'react';
import { Box, Typography } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InputAdornment from '@mui/material/InputAdornment';

import TextFieldLabelKeyboardComponent from '@giro-pdv-whitelabel/components/TextFieldLabelKeyboard.component';

import FieldLabelFormik from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';

import messages from '@giro/shared-constants/messagesSchema.constant';

export const validationSchema = Yup.object({
  renavam: Yup.string().required(messages.REQUIRED),
});

const FormRenavamComponent = (props) => {
  return (
    <TextFieldLabelKeyboardComponent
      disabled={props?.disabled}
      name="renavam"
      label="Renavam"
      size="small"
    />
  );
};

export default FormRenavamComponent;
