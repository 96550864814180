import reducer from './onboarding.reducer';
import * as selector from './onboarding.selector';
import * as constant from './onboarding.constant';
import subscriber from './onboarding.subscriber';
import saga from './onboarding.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
