import reducer from './settings.reducer';
import * as constant from './settings.constant';
import * as action from './settings.action';
import * as selector from './settings.selector';
import saga from './settings.saga';
import initialState from './settings.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
