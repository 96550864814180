import { createReducer } from '@reduxjs/toolkit';

import initialState from './tablePayment.initialState';
import { ACTION_TYPES } from './tablePayment.constant';

const handleUpdateAmount = (state, action) => {
  state.amount = action.payload;
};

const handleInstallmentSelected = (state, action) => {
  state.installmentSelected = action.payload;
};

const handleTypeTax = (state, action) => {
  state.typeTax = action.payload;
};

const handleTypeTaxCredit = (state, action) => {
  state.typeTaxCredit = action.payload;
};

const handleReturn = (state, action) => {
  state.return = action.payload;
};

const handleRows = (state, action) => {
  state.rows = action.payload;
};

const handleResetState = () => initialState;

export default createReducer(initialState, {
  [ACTION_TYPES.RESET.STATE]: handleResetState,
  [ACTION_TYPES.UPDATE.AMOUNT]: handleUpdateAmount,
  [ACTION_TYPES.UPDATE.INSTALLMENT_SELECTED]: handleInstallmentSelected,
  [ACTION_TYPES.UPDATE.TYPE_TAX]: handleTypeTax,
  [ACTION_TYPES.UPDATE.RETURN]: handleReturn,
  [ACTION_TYPES.UPDATE.ROWS]: handleRows,
  [ACTION_TYPES.UPDATE.TYPE_TAX_CREDIT]: handleTypeTaxCredit,
});
