import React from 'react';
import { TextField } from 'formik-mui';
import { flow } from 'lodash';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/lab/LoadingButton/LoadingButton';
import {
  Box,
  MenuItem,
  FormControlLabel,
  Radio,
  Slider,
  Typography,
  RadioGroup,
  CircularProgress,
  Backdrop,
} from '@mui/material';

import PDVLayout from '../layouts/PDV.layout';

import UpdateAmountComponent from '../components/UpdateAmount.component';
import FieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';
import TextFieldLabelComponent from '@giro-pdv-whitelabel/components/TextFieldLabel.component';
import FieldPhoneComponent from '@giro/shared-components/Fields/FieldPhone.component';
import FieldCurrencyComponent, {
  FieldCurrencyComponentDigits,
} from '@giro/shared-components/Fields/FieldCurrency.component';
import ButtonPayActionsComponent from '../components/ButtonPayActions.component';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';
import operators from '../store/recharge/operators';
import amount from '../store/recharge/amount';

const FormRecharge = () => {
  const { user } = useAuthHook();

  const dispatch = useDispatch();

  const dispatchRedux = {
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
    updateFiltersOperators: flow(operators.action.updateFilters, dispatch),
    updateFiltersAmount: flow(amount.action.updateFilters, dispatch),
    resetStateAmount: flow(amount.action.resetState, dispatch),
    resetStateOperators: flow(operators.action.resetState, dispatch),
  };

  const selectorRedux = {
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
    operators: useSelector(operators.selector.selectState),
    amount: useSelector(amount.selector.selectState),
  };

  // React.useEffect(() => {
  //   dispatchRedux.serviceGetOperators();
  // }, []);

  const initialValues = {
    operator: '',
    total: '',
    fees: 0,
    total_amount: '',
    phone_number: '',
    ddd: '',
    produtoId: '',
    historicoId: '',
    segment: '13',
    type_tax: 'client',
  };

  const fetchedOperators =
    Array.from(selectorRedux.operators?.data?.value?.listaOperadoras || [])
      .length > 0;

  const onSubmit = async (values) => {
    console.log('values', values);

    return true;
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue, handleSubmit, resetForm }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <UpdateAmountComponent />

              <Box display="flex" flexDirection="column" gap={4}>
                <FieldPhoneComponent
                  disabled={fetchedOperators}
                  customInput={TextFieldLabelComponent}
                  name="phone_number"
                  autoFocus
                  label="Telefone"
                  size="small"
                  onBlur={({ target: { value } }) => {
                    if (String(values?.phone_number).length >= 10) {
                      dispatchRedux.updateFiltersOperators({
                        phoneNumber: values?.phone_number,
                      });
                    }
                  }}
                />

                <FieldLabelFormikComponent
                  name="operator"
                  size="small"
                  label="Operadora"
                  onChange={({ target: { value } }) => {
                    setFieldValue('operator', value);

                    const currentOperator =
                      selectorRedux.operators?.data?.value;

                    setFieldValue('total', null);
                    setFieldValue('ddd', null);
                    setFieldValue('produtoId', null);
                    setFieldValue('historicoId', null);

                    dispatchRedux.resetStateAmount();

                    setTimeout(() => {
                      dispatchRedux.updateFiltersAmount({
                        operatorId: value,
                        historyId: currentOperator?.historicoId,
                        phoneNumber: values?.phone_number,
                      });
                    });
                  }}
                  select
                  defaultValue=""
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {selectorRedux.operators?.data?.value?.listaOperadoras?.map(
                    (op, idx) => (
                      <MenuItem
                        value={op?.idOperadora}
                        key={`${op?.descricao}-${idx}`}
                      >
                        {op?.descricao}
                      </MenuItem>
                    )
                  )}
                </FieldLabelFormikComponent>

                <Box display="grid" gridTemplateColumns={'1fr 1fr'} gap={4}>
                  <FieldLabelFormikComponent
                    name="total_amount"
                    size="small"
                    label="Valor"
                    select
                    onChange={({ target: { value } }) => {
                      setFieldValue('total', value + (values.fees || 0));
                      setFieldValue('total_amount', value);

                      const currentAmount = selectorRedux.amount?.data?.value;
                      const currentAmountProduct =
                        selectorRedux.amount?.data?.value?.listaProdutos?.find(
                          (lp) => lp?.valor === value
                        );

                      setFieldValue('ddd', currentAmount?.ddd);
                      setFieldValue(
                        'produtoId',
                        currentAmountProduct?.idProduto
                      );
                      setFieldValue('historicoId', currentAmount?.historicoId);
                    }}
                  >
                    {selectorRedux.amount?.data?.value?.listaProdutos?.map(
                      (lp) => (
                        <MenuItem value={lp?.valor} key={lp?.idProduto}>
                          {Number(lp?.valor).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </MenuItem>
                      )
                    )}
                  </FieldLabelFormikComponent>
                  <Box display="flex" flexDirection="column">
                    <FieldCurrencyComponentDigits
                      customInput={TextFieldLabelComponent}
                      size="small"
                      label="Honorários"
                      name="fees"
                      onChange={(val) => {
                        setFieldValue(
                          'total',
                          Number(values.total_amount || 0) + Number(val)
                        );
                      }}
                    />
                  </Box>
                </Box>

                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="trasso_heading_small">
                      Tipo de taxa
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="portador"
                      name="radio-buttons-group"
                      onChange={(ev, value) => {
                        setFieldValue('type_tax', value);

                        dispatchRedux.updateTypeTax(value);

                        if (value === 'ec') {
                          dispatchRedux.updateReturn(0);
                        }
                      }}
                    >
                      <FormControlLabel
                        value="client"
                        checked={values.type_tax === 'client'}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'secondary.main',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="trasso_body_medium"
                            color="grey.900"
                          >
                            Portador
                          </Typography>
                        }
                      />
                      {user?.user?.codes?.ec === 1 && (
                        <FormControlLabel
                          value="ec"
                          checked={values.type_tax === 'ec'}
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: 'trasso.pink',
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="trasso_body_medium"
                              color="trasso.purple.100"
                            >
                              EC
                            </Typography>
                          }
                        />
                      )}
                    </RadioGroup>
                  </Box>
                </Box>

                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    dispatchRedux.resetStateOperators();
                    dispatchRedux.resetStateAmount();

                    resetForm();

                    document.querySelector('#containerScroll')?.scrollTo(0, 0);
                  }}
                >
                  Limpar pesquisa
                </Button>

                <ButtonPayActionsComponent
                  loading={selectorRedux.transactionsLoading}
                  onClickPay={() =>
                    dispatchRedux.transactionsServicePost({
                      ...values,
                    })
                  }
                  disabledPay={
                    selectorRedux.transactionsLoading ||
                    selectorRedux.installmentSelected === null ||
                    Number((values as any).total) === 0
                  }
                  disabledSend={Number((values as any).total) === 0}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={selectorRedux.amount.loading || selectorRedux.operators.loading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const RechargeScreen = () => {
  return (
    <PDVLayout>
      <FormRecharge />
    </PDVLayout>
  );
};

export default RechargeScreen;
