import qs from 'qs';

import fetch from '@giro/shared-utils/fetch.util';

export default async function getApiTransactionstatesLinkedkeyService(id) {
  const url = `transactionstates/${id}`;

  return fetch(url, {
    method: 'GET',
    ms: 'PDV',
    auth: true,
  });
}
