import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import GlobalStyles from '@mui/material/GlobalStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { Document, Page, pdfjs } from 'react-pdf';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';
import * as Yup from 'yup';
import { Autocomplete } from 'formik-mui';
import { pickBy } from 'lodash';
import LogRocket from 'logrocket';

import banksConstant from '@giro-pdv-whitelabel/constants/banks.constant';

import postApiClientsOnboardingsService from '@giro/shared-services/pdv/postApiClientsOnboardings.service';
import getApiClientsOnboardingsService from '@giro/shared-services/pdv/getApiClientsOnboardings.service';
import patchApiSettingsService from '@giro/shared-services/pdv/patchApiSettings.service';

import DialogQuantityComponent from '../components/DialogQuantity.component';
import TextFieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';
import DialogTaxComponent from '../components/DialogTax.component';
import FieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';
import FieldDocumentComponent from '@giro/shared-components/Fields/FieldDocument.component';
import TextFieldLabelComponent from '@giro-pdv-whitelabel/components/TextFieldLabel.component';
import FieldZipcode from '@giro/shared-components/Fields/FieldZipcode.component';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import clients from '@giro-pdv-whitelabel/modules/pdv/store/clients';
import onboardingClient from '../store/onboardingClient';

import useFetchHook from '@giro-pdv-whitelabel/hooks/useFetch.hook';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const STEPS = {
  accept_term: 1,
  select_machine: 2,
  address: 3,
  receive: 4,
  confirm: 5,
  final: 6,
};

const OnboardingScreen = () => {
  const lastStep = React.useRef(STEPS.select_machine);

  const patchApiSettings = useFetchHook(patchApiSettingsService, {
    requestOnMount: false,
  });

  const postApiClientsOnboardings = useFetchHook(
    postApiClientsOnboardingsService,
    {
      requestOnMount: false,
    }
  );

  const prepostoData = useFetchHook(
    async (cns) => {
      const responseCns = await fetch(
        'https://api-busca-cartorios.registrocivil.org.br/cartorio',
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            cns: cns,
          }),
        }
      );

      const dataCns = await responseCns.json();

      return [responseCns.ok, dataCns];
    },
    {
      requestOnMount: false,
    }
  );

  const dispatch = useDispatch();

  const onboardingState = useSelector(onboardingClient.selector.selectState);

  const { user } = useAuthHook();

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [showMaquininhas, setShowMaquininhas] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(STEPS.select_machine);
  const [acceptTerm, setAcceptTerm] = React.useState(false);
  const [sendToApi, setSendToApi] = React.useState<any>({});
  const [showTax, setShowTax] = React.useState(false);

  const clientState = useSelector(clients.selector.selectState);

  React.useEffect(() => {
    if (currentStep === STEPS.address) {
      if (sendToApi?.quantity === 0) {
        const nextStep =
          lastStep.current > currentStep ? currentStep - 1 : currentStep + 1;

        setCurrentStep(nextStep);
      }
    }

    if (currentStep === STEPS.confirm) {
      if (sendToApi?.quantity === 0) {
        setAcceptTerm(true);
      }
    }

    lastStep.current = currentStep;
  }, [currentStep]);

  React.useEffect(() => {
    LogRocket.track('OnboardingInit');
  }, []);

  React.useEffect(() => {
    if (postApiClientsOnboardings.isSuccess === true) {
      setCurrentStep(STEPS.final);
    }
  }, [postApiClientsOnboardings.isSuccess]);

  React.useEffect(() => {
    const { cns } =
      JSON.parse(localStorage.getItem('cartorio') as string) || {};

    if (cns) {
      prepostoData.handleFetch(cns);
    }

    if (!cns && user?.user?.clients?.[0]?.cns) {
      prepostoData.handleFetch(user?.user?.clients?.[0]?.cns);
    }
  }, []);

  React.useEffect(() => {
    if (onboardingState.loading === null) {
      dispatch(clients.action.serviceGet());
    }
  }, []);

  React.useEffect(() => {
    if (onboardingState.loading === false && onboardingState?.data?.status) {
      setCurrentStep(STEPS.final);
    }

    if (onboardingState?.data?.valid === true) {
      window.location.href = ROUTES.PDV.ROOT;
    }
  }, [onboardingState.loading]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

  const handleFinalize = async () => {
    const sendToApiOnboarding = {
      document: sendToApi?.bank_account?.document,
      model: sendToApi?.model,
      quantity: sendToApi?.quantity,
      address: {
        postalCode: sendToApi?.address?.postalCode,
        street: sendToApi?.address?.street,
        number: sendToApi?.address?.number,
        complement: sendToApi?.address?.complement,
        neighborhood: sendToApi?.address?.neighborhood,
        city: sendToApi?.address?.city,
        state: sendToApi?.address?.state,
      },
    };

    const sendToApiUpdate = {
      bank_account: pickBy({
        type: sendToApi?.bank_account?.type,
        document: sendToApi?.bank_account?.document,
        bank: sendToApi?.bank_account?.bank?.Banco,
        agency: sendToApi?.bank_account?.agency,
        number: sendToApi?.bank_account?.number,
        number_digit: sendToApi?.bank_account?.number_digit,
        pix_key: sendToApi?.bank_account?.pix_key,
      }),
    };

    postApiClientsOnboardings.handleFetch(sendToApiOnboarding);
    patchApiSettings.handleFetch(sendToApiUpdate);

    LogRocket.track('OnboardingConfirm');

    return true;
  };

  const handleToggleTerm = () => {
    setAcceptTerm(!acceptTerm);
  };

  const stepPdf = (
    <Box
      component={Paper}
      bgcolor="white"
      p={3}
      display="flex"
      flexDirection="column"
      gap={2}
      width="100%"
      position={'relative'}
    >
      <Box display="flex" flexDirection="column">
        <Document
          loading={
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          }
          style={{ width: '100%' }}
          file="/pdf-open-parameters.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>

        <Box alignSelf="center" display="flex" gap={2} alignItems="center">
          <IconButton
            color="secondary"
            disabled={pageNumber === 1}
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          <Box>
            <Typography
              variant="trasso_body_medium"
              fontWeight={500}
              color="grey.500"
            >
              {pageNumber} / {numPages}
            </Typography>
          </Box>

          <IconButton
            disabled={pageNumber === numPages}
            color="secondary"
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <FormControlLabel
          onChange={handleToggleTerm}
          control={<Checkbox defaultChecked={acceptTerm} />}
          label={
            <Typography variant="trasso_body_medium" fontWeight={500}>
              Aceito os termos de uso
            </Typography>
          }
        />
      </Box>

      <Button
        variant="contained"
        disabled={!acceptTerm}
        onClick={() => {
          LogRocket.track('OnboardingPDF');
          setCurrentStep(currentStep + 1);
        }}
      >
        <Typography variant="trasso_body_medium" fontWeight={500}>
          Continuar
        </Typography>
      </Button>

      {(loading || onboardingState?.loading) && (
        <Box
          position="absolute"
          bgcolor="white"
          width="100%"
          height="100%"
          top={0}
          left={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={9999}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  const stepMachine = (
    <>
      <Box
        component={Paper}
        bgcolor="white"
        p={3}
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%"
      >
        <Formik
          initialValues={{
            quantity: sendToApi?.quantity || 1,
            model: sendToApi?.model || 'gertec',
          }}
          onSubmit={async (values) => {
            LogRocket.track('OnboardingMachine');

            if (values.quantity > 5) {
              setShowMaquininhas(true);

              return true;
            }

            setSendToApi({
              ...sendToApi,
              ...values,
            });

            setCurrentStep(currentStep + 1);

            return true;
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap={5}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Solicitação de equipamento
                  </Typography>

                  <Divider sx={{ mb: 2 }} />

                  <Box
                    display="grid"
                    width="100%"
                    gap={2}
                    gridTemplateColumns="1fr 100px"
                    color="grey.700"
                  >
                    <TextFieldLabelFormikComponent
                      name="model"
                      label="Equipamento"
                      size="small"
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="gertec">PinPad</option>
                    </TextFieldLabelFormikComponent>

                    <TextFieldLabelFormikComponent
                      name="quantity"
                      label="Quantidade"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 1,
                      }}
                    />
                  </Box>

                  <Box alignSelf="center">
                    {values?.model === 'gertec' && (
                      <img
                        src="/lane3000.png"
                        style={{
                          display: 'block',
                          width: '100%',
                          maxWidth: 400,
                        }}
                      />
                    )}

                    <Button
                      color="info"
                      variant="contained"
                      fullWidth
                      onClick={() => setShowTax(true)}
                      sx={{
                        alignSelf: 'center',
                        mt: 3,
                      }}
                    >
                      Ver taxas
                    </Button>
                  </Box>

                  <Box
                    component={Paper}
                    display="flex"
                    flexDirection="column"
                    p={2}
                  >
                    <Box display="flex" gap={2}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="trasso_body_medium">
                          Quantidade
                        </Typography>
                        <Typography align="center" variant="h4">
                          {values?.quantity}
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        justifyContent="center"
                      >
                        <Typography variant="trasso_body_medium">
                          <strong>Custo unitário:</strong> R$ 25,00
                        </Typography>
                        <Typography variant="trasso_body_medium">
                          <strong>Custo total mensal:</strong>{' '}
                          {Number((values?.quantity || 0) * 25).toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency: 'BRL',
                            }
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  gap={2}
                  flexDirection={{
                    xs: 'column',
                    md: 'row',
                  }}
                >
                  <Button
                    variant="outlined"
                    type="button"
                    fullWidth
                    onClick={() => {
                      setFieldValue('quantity', 0);
                      handleSubmit();
                    }}
                  >
                    <Typography variant="trasso_body_medium" fontWeight={500}>
                      Continuar sem equipamento
                    </Typography>
                  </Button>
                  <Button variant="contained" type="submit" fullWidth>
                    <Typography variant="trasso_body_medium" fontWeight={500}>
                      Continuar com equipamento
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>

      <DialogQuantityComponent
        visible={showMaquininhas}
        handleClose={() => setShowMaquininhas(false)}
      />

      <DialogTaxComponent
        visible={showTax}
        handleClose={() => setShowTax(false)}
      />
    </>
  );

  const stepAddress = (
    <Box
      component={Paper}
      bgcolor="white"
      p={3}
      display="flex"
      flexDirection="column"
      gap={2}
      width="100%"
    >
      <Formik
        initialValues={
          sendToApi?.address || {
            postalCode: clientState?.payload?.cep || '',
            street: clientState?.payload?.endereco?.split(',')?.[0] || '',
            number:
              clientState?.payload?.endereco?.split(',')?.[1]?.trim() || '',
            complement: clientState?.payload?.complemento || '',
            neighborhood: clientState?.payload?.bairro || '',
            city: clientState?.payload?.cidade || '',
            state: clientState?.payload?.uf || '',
          }
        }
        validationSchema={Yup.object({
          postalCode: Yup.string().required('Campo obrigatório'),
          street: Yup.string().required('Campo obrigatório'),
          number: Yup.string().required('Campo obrigatório'),
          neighborhood: Yup.string().required('Campo obrigatório'),
          city: Yup.string().required('Campo obrigatório'),
          state: Yup.string().required('Campo obrigatório'),
        })}
        onSubmit={async (values) => {
          const newValues = { ...values };

          if (newValues?.complement === '') {
            delete newValues.complement;
          }

          setSendToApi({
            ...sendToApi,
            address: newValues,
          });

          setCurrentStep(currentStep + 1);

          LogRocket.track('OnboardingAddress');

          return true;

          // const [success] = await postApiClientsOnboardingsService(valuesToApi);

          // if (success) {
          //   setCurrentStep(currentStep + 1);
          // }

          // return true;
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap={5}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Dados de endereço
                </Typography>

                <Divider sx={{ mb: 2 }} />

                <FieldZipcode
                  customInput={TextFieldLabelComponent}
                  name="postalCode"
                  size="small"
                />

                <TextFieldLabelFormikComponent
                  name="street"
                  label="Rua"
                  size="small"
                />

                <Box
                  display="grid"
                  gridTemplateColumns={{
                    xs: '1fr',
                    md: '1fr 1fr',
                  }}
                  gap={2}
                  width="100%"
                >
                  <TextFieldLabelFormikComponent
                    name="number"
                    label="Número"
                    size="small"
                  />

                  <TextFieldLabelFormikComponent
                    name="complement"
                    label="Complemento"
                    size="small"
                  />
                </Box>

                <Box
                  display="grid"
                  gridTemplateColumns={{
                    xs: '1fr',
                    md: '1fr 1fr 150px',
                  }}
                  gap={2}
                  width="100%"
                >
                  <TextFieldLabelFormikComponent
                    name="neighborhood"
                    label="Bairro"
                    size="small"
                  />

                  <TextFieldLabelFormikComponent
                    name="city"
                    label="Cidade"
                    size="small"
                  />

                  <TextFieldLabelFormikComponent
                    name="state"
                    label="Estado"
                    size="small"
                  />
                </Box>
              </Box>

              <Box display="flex" gap={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                  disabled={isSubmitting}
                >
                  <Typography variant="trasso_body_medium" fontWeight={500}>
                    Voltar
                  </Typography>
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  fullWidth
                  loading={isSubmitting}
                >
                  <Typography variant="trasso_body_medium" fontWeight={500}>
                    Continuar
                  </Typography>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );

  const stepReceive = (
    <Box
      component={Paper}
      bgcolor="white"
      p={3}
      display="flex"
      flexDirection="column"
      gap={2}
      width="100%"
    >
      <Formik
        initialValues={
          sendToApi?.bank_account || {
            type: 'pix',
            document: '',
            bank: '',
            agency: '',
            number: '',
            number_digit: '',
            pix_key: '',
          }
        }
        validationSchema={Yup.object({
          type: Yup.string().required('Campo obrigatório'),
          document: Yup.string().required('Campo obrigatório'),
          bank: Yup.mixed().when('type', {
            is: 'account',
            then: Yup.mixed().required('Campo obrigatório'),
          }),
          agency: Yup.string().when('type', {
            is: 'account',
            then: Yup.string().required('Campo obrigatório'),
          }),
          number: Yup.string().when('type', {
            is: 'account',
            then: Yup.string().required('Campo obrigatório'),
          }),
          number_digit: Yup.string().when('type', {
            is: 'account',
            then: Yup.string().required('Campo obrigatório'),
          }),
          pix_key: Yup.string().when('type', {
            is: 'pix',
            then: Yup.string().required('Campo obrigatório'),
          }),
        })}
        onSubmit={async (values) => {
          const newValues = { ...values };

          if (newValues?.type === 'pix') {
            delete newValues.bank;
            delete newValues.agency;
            delete newValues.number;
            delete newValues.number_digit;
          } else {
            delete newValues.pix_key;
          }

          setSendToApi({
            ...sendToApi,
            bank_account: newValues,
          });

          setCurrentStep(currentStep + 1);
          LogRocket.track('OnboardingReceive');

          return true;

          // const [success] = await postApiClientsOnboardingsService(valuesToApi);

          // if (success) {
          //   setCurrentStep(currentStep + 1);
          // }

          // return true;
        }}
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap={5}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="subtitle1" fontWeight={600}>
                  Conta de recebimento
                </Typography>

                <Divider sx={{ mb: 2 }} />

                <FieldDocumentComponent
                  size="small"
                  name="document"
                  customInput={TextFieldLabelComponent}
                />

                <Box display="flex" gap={1.5}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="trasso_heading_small">
                      Tipo de conta
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={values?.type}
                      name="radio-buttons-group"
                      onChange={(ev, value) => {
                        setFieldValue('type', value);
                      }}
                    >
                      <FormControlLabel
                        value="account"
                        checked={values.type === 'account'}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'secondary.main',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="trasso_body_medium"
                            color="grey.900"
                          >
                            Conta bancária
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="pix"
                        checked={values.type === 'pix'}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'secondary.main',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="trasso_body_medium"
                            color="trasso.purple.100"
                          >
                            PIX
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </Box>
                </Box>

                {values.type === 'pix' && (
                  <FieldLabelFormikComponent
                    size="small"
                    name="pix_key"
                    label="Chave PIX"
                    fullWidth
                  />
                )}

                {values.type === 'account' && (
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1.5}
                  >
                    <Field
                      name="bank"
                      component={Autocomplete}
                      noOptionsText="Nada encontrado"
                      getOptionLabel={(option: any) => option?.BancoNome || ''}
                      options={banksConstant}
                      renderInput={(params) => (
                        <TextFieldLabelComponent
                          {...params}
                          name="bank"
                          error={touched['bank'] && !!errors['bank']}
                          helperText={touched['bank'] && errors['bank']}
                          label="Banco"
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    />

                    <FieldLabelFormikComponent
                      size="small"
                      name="agency"
                      label="Agência"
                    />
                    <Box
                      display="grid"
                      gridTemplateColumns={'1fr 150px'}
                      gap={1.5}
                    >
                      <FieldLabelFormikComponent
                        size="small"
                        name="number"
                        label="Número da conta"
                      />
                      <FieldLabelFormikComponent
                        size="small"
                        name="number_digit"
                        label="Dígito da conta"
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              <Box display="flex" gap={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  <Typography variant="trasso_body_medium" fontWeight={500}>
                    Voltar
                  </Typography>
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  fullWidth
                  // loading={isSubmitting}
                >
                  <Typography variant="trasso_body_medium" fontWeight={500}>
                    Continuar
                  </Typography>
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );

  const stepConfirm = (
    <Box
      component={Paper}
      bgcolor="white"
      p={3}
      display="flex"
      flexDirection="column"
      gap={2}
      width="100%"
    >
      <Box display="flex" flexDirection="column" gap={5}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="subtitle1" fontWeight={600}>
            Confirme seus dados
          </Typography>

          {prepostoData?.data?.dados && (
            <>
              <Divider sx={{ mb: 2 }} />

              <Box display="flex" flexDirection="column" gap={0}>
                <Typography sx={{ mb: 1 }}>Dados do cartório</Typography>

                <Typography variant="caption">
                  Nome: {prepostoData?.data?.dados?.[0]?.nome}
                </Typography>
                <Typography variant="caption">
                  Endereço:{' '}
                  {[
                    prepostoData?.data?.dados?.[0]?.logradouro,
                    prepostoData?.data?.dados?.[0]?.numero,
                  ].join(', ')}
                </Typography>
                <Typography variant="caption">
                  Telefone: ({prepostoData?.data?.dados?.[0]?.ddd_telefone}){' '}
                  {prepostoData?.data?.dados?.[0]?.numero_telefone}
                </Typography>
                <Typography variant="caption">
                  Email: {prepostoData?.data?.dados?.[0]?.email}
                </Typography>
              </Box>
            </>
          )}

          {sendToApi?.quantity > 0 && (
            <>
              <Divider />

              <Box display="flex" flexDirection="column" gap={0}>
                <Typography sx={{ mb: 1 }}>Dados do pedido</Typography>

                <Typography variant="caption">
                  Quantidade: {sendToApi?.quantity}
                </Typography>
                <Typography variant="caption">Equipamento: PinPad</Typography>

                <Typography variant="caption">
                  Custo:{' '}
                  {Number((sendToApi?.quantity || 0) * 25).toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )}{' '}
                  / mês
                </Typography>
              </Box>

              <Divider />

              <Box display="flex" flexDirection="column" gap={0}>
                <Typography sx={{ mb: 1 }}>Dados da entrega</Typography>

                <Typography variant="caption">
                  CEP: {sendToApi?.address?.postalCode}
                </Typography>

                <Typography variant="caption">
                  Endereço: {sendToApi?.address?.street},{' '}
                  {sendToApi?.address?.number}
                </Typography>

                {sendToApi?.address?.complement && (
                  <Typography variant="caption">
                    Complemento: {sendToApi?.address?.complement}
                  </Typography>
                )}

                <Typography variant="caption">
                  Bairro: {sendToApi?.address?.neighborhood}
                </Typography>

                <Typography variant="caption">
                  Cidade e Estado: {sendToApi?.address?.city} -{' '}
                  {sendToApi?.address?.state}
                </Typography>
              </Box>
            </>
          )}

          <Divider />

          <Box display="flex" flexDirection="column" gap={0}>
            <Typography sx={{ mb: 1 }}>Conta de recebimento</Typography>

            <Typography variant="caption">
              Tipo:{' '}
              {sendToApi?.bank_account?.type === 'account'
                ? 'Conta bancária'
                : 'PIX'}
            </Typography>

            <Typography variant="caption">
              Documento: {sendToApi?.bank_account?.document}
            </Typography>

            {sendToApi?.bank_account?.type === 'account' && (
              <>
                <Typography variant="caption">
                  Banco: {sendToApi?.bank_account?.bank?.BancoNome}
                </Typography>
                <Typography variant="caption">
                  Agência: {sendToApi?.bank_account?.agency}
                </Typography>
                <Typography variant="caption">
                  Conta: {sendToApi?.bank_account?.number}-
                  {sendToApi?.bank_account?.number_digit}
                </Typography>
              </>
            )}

            {sendToApi?.bank_account?.type === 'pix' && (
              <Typography variant="caption">
                Chave PIX: {sendToApi?.bank_account?.pix_key}
              </Typography>
            )}
          </Box>

          {sendToApi?.quantity > 0 && (
            <>
              <Divider />

              <Box>
                <FormControlLabel
                  onChange={handleToggleTerm}
                  control={<Checkbox defaultChecked={acceptTerm} />}
                  label={
                    <Typography
                      variant="trasso_body_small"
                      fontWeight={500}
                      color="grey.600"
                    >
                      Ao aceitar, o seu Estabelecimento concorda com o valor da
                      mensalidade acima.
                    </Typography>
                  }
                />
              </Box>
            </>
          )}
        </Box>

        <Box display="flex" gap={2}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            <Typography variant="trasso_body_medium" fontWeight={500}>
              Voltar
            </Typography>
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            disabled={!acceptTerm}
            loading={postApiClientsOnboardings.loading}
            onClick={() => handleFinalize()}
          >
            <Typography variant="trasso_body_medium" fontWeight={500}>
              Finalizar
            </Typography>
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );

  const stepFinish = (
    <Box
      component={Paper}
      bgcolor="white"
      p={3}
      display="flex"
      flexDirection="column"
      gap={2}
      width="100%"
      position={'relative'}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography>
          Parabéns! Bem-vindo ao marco na modernização dos serviços cartorários
          do Brasil. Agora começa sua revolução na era digital do Registro Civil
          do Brasil.
        </Typography>

        <Typography variant="trasso_body_medium" color="grey.600">
          Pedido Realizado com Sucesso. Logo sua Serventia receberá o(s)
          equipamento(s).
        </Typography>

        {/* <Typography variant="trasso_body_medium" color="grey.600">
          Status: {onboardingState?.data?.status}
        </Typography> */}

        {onboardingState?.data?.delivery_code && (
          <Typography variant="trasso_body_medium" color="grey.600">
            Código de rastreio: {onboardingState?.data?.delivery_code}
          </Typography>
        )}
      </Box>

      <Button
        variant="contained"
        onClick={() => {
          window.location.href = ROUTES.PDV.ROOT;
        }}
      >
        <Typography variant="trasso_body_medium" fontWeight={500}>
          Ok
        </Typography>
      </Button>
    </Box>
  );

  return (
    <>
      <GlobalStyles
        styles={`
          body {
            background-color: #F4F0F7;
          }

          #header-app, #footer-app {
            display: none;
          }
        `}
      />

      <Box
        minHeight="calc(100vh - 64px)"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={5}
          p={3}
          width={{
            xs: '100%',
            md: 642,
          }}
        >
          <Box alignSelf="center">
            <img src="/logorc.png" width={200} style={{ display: 'block' }} />
          </Box>

          {currentStep === STEPS.accept_term && stepPdf}
          {currentStep === STEPS.select_machine && stepMachine}
          {currentStep === STEPS.address && stepAddress}
          {currentStep === STEPS.receive && stepReceive}
          {currentStep === STEPS.confirm && stepConfirm}
          {currentStep === STEPS.final && stepFinish}
        </Box>
      </Box>
    </>
  );
};

export default OnboardingScreen;
