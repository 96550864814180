import fetch from '@giro/shared-utils/fetch.util';

export type BodyType = {
  status: 'PAGO' | 'REJEITADO';
  mensagem: string;
};

export default async function patchApiTransactionstatesLinkedkeyService(
  id,
  body: BodyType
) {
  return fetch(`transactionstates/${id}`, {
    method: 'PATCH',
    ms: 'PDV',
    auth: true,
    body: JSON.stringify(body),
  });
}
