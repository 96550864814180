import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import auth from '@giro/shared-store/auth';

export default function useProtectedByAuth() {
  const history = useHistory();

  const selectorRedux = {
    signedIn: useSelector(auth.selector.selectSignedIn),
    state: useSelector(auth.selector.selectState),
  };

  const { signedIn } = selectorRedux;

  const authclientUrl = ROUTES.LOGIN.ROOT;

  useEffect(() => {
    if (!signedIn) {
      let redirectStr = `${authclientUrl}?redirectUrl=${window.location.href}`;

      if (selectorRedux.state?.reason && selectorRedux.state?.reason !== '') {
        redirectStr += `&err=${selectorRedux.state.reason}`;
      }

      window.location.replace(redirectStr);
    }
  }, [signedIn]);
}
