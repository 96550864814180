import { Switch, Route } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import useProtectedByAuth from '@giro-pdv-whitelabel/hooks/useProtectedByAuth.hook';

import AppLayout from '@giro-pdv-whitelabel/layouts/App.layout';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

const Content = () => {
  return (
    <ul>
      <li>
        <Link
          href={'https://saas.sipe.registrocivil.org.br/documentation'}
          target="_blank"
          color="primary.dark"
          fontWeight="bold"
        >
          Swagger da API (https://saas.sipe.registrocivil.org.br/documentation)
        </Link>
      </li>
    </ul>
  );
};

const DocumentationModule = () => {
  useProtectedByAuth();

  return (
    <AppLayout>
      <Switch>
        <Route
          exact
          path={ROUTES.DOCUMENTATION}
          component={() => <Content />}
        />
      </Switch>
    </AppLayout>
  );
};

export default DocumentationModule;
