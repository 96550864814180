const PALETTE_PRIMARY = {
  main: '#3ABBC6',
  light: '#9bdaf6',
  dark: '#5b92aa',
};

const PALLET_SECONDARY = {
  main: '#C8CF4B',
  light: '#d3d86f',
  dark: '#8c9034',
};

export { PALETTE_PRIMARY, PALLET_SECONDARY };
