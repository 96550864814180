import React from 'react';
import { Box, GlobalStyles, Typography, Button } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import linkedkey from '../store/linkedkey';

const CbScreen = () => {
  const { signedIn } = useAuthHook();
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const history = useHistory();

  const linkedKeyState = useSelector(linkedkey.selector.selectState);

  React.useEffect(() => {
    if (!id) return undefined;

    if (!signedIn) {
      localStorage.setItem(
        process.env.REACT_APP_SESSION_LINKED_STATE_KEY as string,
        id
      );

      (window as any).location.href = process.env.REACT_APP_CB_LOGIN_URL;

      return undefined;
    }

    dispatch(linkedkey.action.serviceGet(id));
  }, [id, signedIn]);

  React.useEffect(() => {
    const [hasError] = linkedKeyState?.error || [];

    if (hasError) {
      return undefined;
    }

    const { revisar } = linkedKeyState?.data || {};

    if (revisar === false) {
      history.push(`${ROUTES.PDV.CARTORIO}?linkedState=${id}`);
    }
  }, [linkedKeyState.loading]);

  return (
    <>
      <Box
        minHeight="calc(100vh - 251px)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {(!signedIn || linkedKeyState.loading) && (
          <Typography>Carregando...</Typography>
        )}

        {linkedKeyState.loading === false && (
          <Box
            display="flex"
            p={2}
            borderRadius={2}
            border="1px solid transparent"
            borderColor="grey.300"
            flexDirection="column"
            gap={0.5}
            width={600}
          >
            <Typography variant="header_sm" mb={2}>
              Você desenha pagar esta cobrança?
            </Typography>
            <Typography>
              <strong>Descrição:</strong> {linkedKeyState?.data?.descricao}
            </Typography>
            <Typography>
              <strong>Pagamento:</strong> {linkedKeyState?.data?.pagamento}
            </Typography>
            <Typography>
              <strong>Parcelas:</strong> {linkedKeyState?.data?.parcelas}
            </Typography>

            <Box display="flex" gap={2} mt={3}>
              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={() => {
                  dispatch(linkedkey.action.resetState());

                  history.push(`${ROUTES.PDV.CARTORIO}?linkedState=${id}`);
                }}
              >
                Rejeitar
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={() => history.push(`${ROUTES.PDV.CARTORIO}`)}
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CbScreen;
