import React from 'react';
import { useHistory } from 'react-router-dom';

import useQueryHook from '@giro/shared-hooks/useQuery.hook';

const LoginScreen = () => {
  const query = useQueryHook();

  const history = useHistory();

  const errQuery = query.get('err');

  React.useEffect(() => {
    if (errQuery !== null) {
      history.push(`/login?err=${errQuery}`);
    }
  }, [errQuery]);

  return <></>;
};

export default LoginScreen;
