import React from 'react';
import { flow } from 'lodash';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import segmentNormalizeConstant from '@giro-pdv-whitelabel/constants/segmentNormalize.constant';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';
import useIsMobile from '@giro-pdv-whitelabel/hooks/useIsMobile.hook';
import useIsTotemHook from '@giro-pdv-whitelabel/hooks/useTotem.hook';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import useIsCreaHook from '@giro-pdv-whitelabel/hooks/useIsCrea.hook';

import ROUTES, {
  NAMESPACES,
} from '@giro-pdv-whitelabel/constants/routes.constant';

import auth from '@giro/shared-store/auth';
import menuStore from '@giro-pdv-whitelabel/store/menu';

export const menuSidebarConstant = [
  {
    label: 'DETRANs',
    route: ROUTES.PDV.DETRAN,
    segment: segmentNormalizeConstant.DETRAN,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#shield" />
      </svg>
    ),
  },
  {
    label: 'Totem',
    route: ROUTES.PDV.TOTEM,
    segment: segmentNormalizeConstant.TOTEM,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#monitor" />
      </svg>
    ),
  },
  {
    label: 'Boletos',
    route: ROUTES.PDV.BILLET,
    segment: segmentNormalizeConstant.BOLETO,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#align-justify" />
      </svg>
    ),
  },
  {
    label: 'Financiamento',
    route: ROUTES.PDV.FINANCING,
    segment: segmentNormalizeConstant.FINANCIAMENTO,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#divide" />
      </svg>
    ),
  },
  // {
  //   label: 'PIX',
  //   module: NAMESPACES.PDV.substring(1),
  //   route: ROUTES.PDV.ROOT,
  //   icon: (
  //     <svg
  //       width="16"
  //       height="16"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <use href="/feather-sprite.svg#dollar-sign" />
  //     </svg>
  //   ),
  // },
  // {
  //   label: 'Seguros',
  //   module: NAMESPACES.PDV.substring(1),
  //   route: ROUTES.PDV.ROOT,
  //   icon: (
  //     <svg
  //       width="16"
  //       height="16"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <use href="/feather-sprite.svg#dollar-sign" />
  //     </svg>
  //   ),
  // },
  // {
  //   label: 'Crédito',
  //   module: NAMESPACES.PDV.substring(1),
  //   route: ROUTES.PDV.ROOT,
  //   icon: (
  //     <svg
  //       width="16"
  //       height="16"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <use href="/feather-sprite.svg#dollar-sign" />
  //     </svg>
  //   ),
  // },
  // {
  //   label: 'Agenda de recebíveis',
  //   module: NAMESPACES.PDV.substring(1),
  //   route: ROUTES.PDV.ROOT,
  //   icon: (
  //     <svg
  //       width="16"
  //       height="16"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <use href="/feather-sprite.svg#dollar-sign" />
  //     </svg>
  //   ),
  // },
  {
    label: 'Recarga de celular',
    route: ROUTES.PDV.RECHARGE,
    segment: segmentNormalizeConstant.RECHARGE,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#smartphone" />
      </svg>
    ),
  },
];

const MenuRender = ({
  menu,
  history,
  toggleCollapse,
  currentModule,
  concatCss,
  collapseArr,
  pathname,
  label,
  sideOpen,
}) => {
  const isTotem = useIsTotemHook();

  return (
    <Box display="flex" flexDirection="column">
      {label && sideOpen && menu.length > 0 && (
        <Typography
          variant="trasso_heading_small"
          color="#D776FF"
          fontSize="10px"
          py="12px"
          px={4}
          sx={{ textTransform: 'uppercase' }}
        >
          {label}
        </Typography>
      )}
      <List sx={{ p: 0 }}>
        {menu.map((el: any, index) => (
          <React.Fragment
            key={`sidebar-${el}-${index}-${sideOpen ? 'open' : 'close'}}`}
          >
            <Tooltip
              placement="right"
              title={el.label}
              open={sideOpen ? false : undefined}
            >
              <ListItemButton
                onClick={
                  el.submenu
                    ? () => toggleCollapse(index)
                    : () => history.push(el.route)
                }
                key={el.label}
                sx={{
                  height: '40px',
                  pl: 4,
                  justifyContent: 'space-between',
                  ...(Object.assign(
                    {},
                    (currentModule === el.module || el.route === pathname) &&
                      concatCss
                  ) as any),
                  '&:hover': {
                    '.MuiTypography-root': {
                      color: isTotem ? 'evora.50' : 'trasso.pink',
                    },
                  },
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1.5}
                  color={isTotem ? 'evora.50' : 'trasso.pink'}
                >
                  {el.icon}

                  {sideOpen && (
                    <Typography
                      variant="trasso_heading_small"
                      color="trasso.purple.60"
                    >
                      {el.label}
                    </Typography>
                  )}
                </Box>

                {el.submenu && (
                  <Box position="absolute" right={sideOpen ? 16 : 4} top={8}>
                    {collapseArr.includes(index) ? (
                      <ExpandLess sx={{ color: 'trasso.purple.60' }} />
                    ) : (
                      <ExpandMore sx={{ color: 'trasso.purple.60' }} />
                    )}
                  </Box>
                )}
              </ListItemButton>
            </Tooltip>

            {el.submenu && (
              <Collapse
                in={collapseArr.includes(index)}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {el.submenu.map((els, index) => (
                    <Tooltip
                      placement="right"
                      title={els.label}
                      open={sideOpen ? false : undefined}
                      key={`submenu-${el}-${els}-${index}-${
                        sideOpen ? 'open' : 'close'
                      }`}
                    >
                      <ListItemButton
                        onClick={() => history.push(els.route)}
                        sx={{
                          height: '40px',
                          pl: sideOpen ? 8 : 6,
                          ...(Object.assign(
                            {},
                            pathname === els.route && concatCss
                          ) as any),
                          '&:hover': {
                            '.MuiTypography-root': {
                              color: isTotem ? 'evora.50' : 'trasso.pink',
                            },
                          },
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1.5}
                          color={isTotem ? 'evora.50' : 'trasso.pink'}
                        >
                          {els.icon}

                          {sideOpen && (
                            <Typography
                              variant="trasso_heading_small"
                              color="trasso.purple.60"
                            >
                              {els.label}
                            </Typography>
                          )}
                        </Box>
                      </ListItemButton>
                    </Tooltip>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

const SidebarComponent = ({ sideOpen = true }) => {
  const isMobile = useIsMobile();
  const isTotem = useIsTotemHook();

  const { user } = useAuthHook();

  const dispatch = useDispatch();

  const history = useHistory();
  const { pathname } = useLocation();

  const selectorRedux = {
    collapses: useSelector(menuStore.selector.selectCollapses),
  };

  const dispatchRedux = {
    unsetToken: flow(auth.action.unsetToken, dispatch),
    updateCollapses: flow(menuStore.action.updateCollapses, dispatch),
  };

  const isCrea = useIsCreaHook();

  const segment = user?.user?.codes?.segment;
  const { email = '' } = user?.user || {};

  const menuSub = menuSidebarConstant.filter((el) =>
    segment?.includes(el.segment)
  );

  const menu = [
    !isTotem &&
      !isCrea && {
        label: 'PDV',
        module:
          !menuSub.some((ms) => ms.route === pathname) &&
          NAMESPACES.PDV.substring(1),
        route: ROUTES.PDV.ROOT,
        icon: (
          <svg
            width="16"
            height="16"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <use href="/feather-sprite.svg#dollar-sign" />
          </svg>
        ),
      },
    !isTotem && {
      label: 'Relatórios',
      module: NAMESPACES.REPORTS.substring(1),
      route: ROUTES.REPORTS.ROOT,
      icon: (
        <svg
          width="16"
          height="16"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href="/feather-sprite.svg#clipboard" />
        </svg>
      ),
      submenu: [
        !isCrea && {
          label: 'EC',
          route: ROUTES.REPORTS.EC,
          icon: (
            <svg
              width="16"
              height="16"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <use href="/feather-sprite.svg#clipboard" />
            </svg>
          ),
        },
        !isCrea &&
          segment?.includes(segmentNormalizeConstant.FINANCIAMENTO) && {
            label: 'Financiamento',
            route: ROUTES.REPORTS.FINANCING,
            icon: (
              <svg
                width="16"
                height="16"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <use href="/feather-sprite.svg#clipboard" />
              </svg>
            ),
          },
        isCrea && {
          label: 'Crea',
          route: ROUTES.REPORTS.CREA,
          icon: (
            <svg
              width="16"
              height="16"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <use href="/feather-sprite.svg#clipboard" />
            </svg>
          ),
        },
      ].filter(Boolean),
    },
  ].filter(Boolean);

  const menuDown = [
    {
      label: 'Sair',
      onClick: () => dispatchRedux.unsetToken(),
      icon: (
        <svg
          width="16"
          height="16"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href="/feather-sprite.svg#log-out" />
        </svg>
      ),
    },
  ];

  const getModule = () => {
    return pathname.split('/')[1];
  };

  const currentModule = getModule();

  const concatCss = {
    backgroundColor: 'trasso.gray.30',
    '& .MuiTypography-root': {
      color: isTotem ? 'evora.50' : 'trasso.pink',
    },
  };

  const toggleCollapse = (index) => {
    if (selectorRedux.collapses.includes(index)) {
      const newArr = [...selectorRedux.collapses.filter((el) => el !== index)];

      return dispatchRedux.updateCollapses([...newArr]);
    }

    return dispatchRedux.updateCollapses([...selectorRedux.collapses, index]);
  };

  return (
    <Box
      width={sideOpen ? 244 : 82}
      bgcolor={'trasso.gray.20'}
      borderRight="2px solid transparent"
      borderColor="trasso.gray.40"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      className="sidebar-container"
      sx={{
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <Box display="flex" flexDirection="column">
        <Tooltip
          open={sideOpen ? false : undefined}
          placement="right"
          title={email}
          key={`tooltip-${sideOpen ? 'open' : 'close'}`}
        >
          <Box py={2} px={4} display="flex" alignItems="center" gap={1.5}>
            <Box color={isTotem ? 'evora.50' : 'trasso.pink'}>
              <svg
                width="16"
                height="16"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <use href="/feather-sprite.svg#user" />
              </svg>
            </Box>
            {sideOpen && (
              <Tooltip title={email} placement="right">
                <Typography
                  variant="trasso_heading_small"
                  color="trasso.purple.60"
                  sx={{
                    width: 150,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {email}
                </Typography>
              </Tooltip>
            )}
          </Box>
        </Tooltip>

        <MenuRender
          menu={menu}
          collapseArr={selectorRedux.collapses}
          toggleCollapse={toggleCollapse}
          currentModule={currentModule}
          concatCss={concatCss}
          label={isCrea ? null : 'veículos'}
          history={history}
          pathname={pathname}
          sideOpen={sideOpen}
        />

        {!isCrea && menuSub.length > 0 && !isMobile && (
          <>
            <Box width="100%" height="2px" bgcolor="trasso.gray.40" my={2} />

            <MenuRender
              menu={menuSub}
              collapseArr={selectorRedux.collapses}
              toggleCollapse={toggleCollapse}
              currentModule={currentModule}
              concatCss={concatCss}
              label="outros pagamentos"
              history={history}
              pathname={pathname}
              sideOpen={sideOpen}
            />
          </>
        )}
      </Box>

      <Box>
        {sideOpen && (
          <>
            <Box display="flex" flexDirection="column" px={4} gap={2}>
              <Typography
                variant="trasso_heading_small"
                color="trasso.purple.60"
              >
                Bandeiras aceitas:
              </Typography>
              <Box display="grid" gridTemplateColumns="1fr">
                <img src="/brands.png" />
              </Box>
            </Box>

            <Box
              width="100%"
              height="2px"
              bgcolor="trasso.gray.40"
              mt={4}
              mb={0}
            />
          </>
        )}
        <List sx={{ p: 0 }}>
          {menuDown.map((el, index) => (
            <Tooltip
              open={sideOpen ? false : undefined}
              placement="right"
              title={el.label}
              key={`${el.label}-${sideOpen ? 'open' : 'close'}}`}
            >
              <ListItemButton
                onClick={() => el?.onClick()}
                key={el.label}
                sx={{
                  height: '40px',
                  pl: 4,
                  '&:hover': {
                    '.MuiTypography-root': {
                      color: isTotem ? 'evora.50' : 'trasso.pink',
                    },
                  },
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1.5}
                  color={isTotem ? 'evora.50' : 'trasso.pink'}
                >
                  {el.icon}

                  {sideOpen && (
                    <Typography
                      variant="trasso_heading_small"
                      color="trasso.purple.60"
                    >
                      {el.label}
                    </Typography>
                  )}
                </Box>
              </ListItemButton>
            </Tooltip>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default SidebarComponent;
