import reducer from './cb.reducer';
import * as selector from './cb.selector';
import * as constant from './cb.constant';
import subscriber from './cb.subscriber';
import saga from './cb.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
