import React from 'react';
import { Box, Link, Button } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import fetch from '@giro/shared-utils/fetch.util';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import reportsFinancing from '@giro-pdv-whitelabel/modules/reports/store/reportsFinancing';

const banksToFinancing = [
  '336',
  '341',
  '342',
  '394',
  '422',
  '623',
  '655',
  'fontecred',
  'M22',
  'moneyplus',
  '033',
];

const envCredere = 'https://staging.eita.io/api/v1/';
const authCredere =
  '15859d76d5a712223e8852585713b94c65534a57975caa331aac922ee6459742';

const FinancingScreen = () => {
  const isFirst = React.useRef(true);

  const selectorsRedux = {
    data: useSelector(reportsFinancing.selector.selectData),
  };

  const history = useHistory();
  const { user } = useAuth();

  const { id: idParams } = useParams<any>();

  const [step, setStep] = React.useState<any>(null);
  const [data, setData] = React.useState<any>({});

  const windowConst = window as any;

  const handleExecuteStep = () => {
    if (!step) {
      return null;
    }

    if (step == 1) {
      handleStep1();
    }

    if (step == 2) {
      handleStep2();
    }

    if (step == 3) {
      handleStep3();
    }
  };

  const handleStep1 = () => {
    windowConst.CREDERE = windowConst.CREDERE || {};

    windowConst.CREDERE.environment = envCredere;
    windowConst.CREDERE.authorization = authCredere;

    windowConst.CREDERE.settings = {
      simulations: {
        form: {
          store_id: 90,
          webhook_url: 'https://webhook.site/api/v1/webhooks',
          bank_codes: banksToFinancing,
          _dynamic_banks: true,
        },
      },
    };

    windowConst.CREDERE.callbacks = {
      'simulation:create': function (dataCb) {
        const dataSend = {
          idLoja: 90,
          idSimulador: dataCb?.data?.uuid,
          // idSimulador: 'e0c2ff6e-f1c4-4a5f-8451-3223e1a9cdcf', // Identificar o cliente
          documento: dataCb.data.lead.cpf_cnpj,
          step1: dataCb,
        };

        (async () => {
          try {
            const [success, resultJson] = await fetch(
              'transactions-financing',
              {
                method: 'POST',
                ms: 'PDV',
                auth: true,
                body: JSON.stringify({
                  id: null,
                  idFinanciamento: dataCb?.data?.uuid,
                  userId: String(user?.user?.codes?.idUser),
                  fase: '2',
                  metadata: JSON.stringify(dataSend),
                }),
              }
            );
          } catch (error) {
            console.log(error);
          }
        })();

        setData(dataSend);

        setStep(2);
      },
    };

    windowConst.CREDERE.src =
      'https://embed.meucredere.com.br/simulations/form';

    const credereJS = document.createElement('script');
    credereJS.type = 'text/javascript';
    credereJS.src = 'https://embed.meucredere.com.br/initialize.js';
    credereJS.id = 'credere-script';
    document.body.append(credereJS);
  };

  const handleStep2 = () => {
    const idLoja = data?.idLoja;
    const idSimulador = data?.idSimulador;

    windowConst.CREDERE = windowConst.CREDERE || {};

    windowConst.CREDERE.environment = envCredere;
    windowConst.CREDERE.authorization = authCredere;

    windowConst.CREDERE.settings = {
      simulations: {
        read: {
          uuid: idSimulador,
          store_id: idLoja,
        },
      },
    };

    windowConst.CREDERE.callbacks = {
      'simulation:read:action': function (dataCb) {
        if (dataCb.action === 'customers/form') {
          setData({
            ...data,
            step2: dataCb,
          });

          setStep(3);
        }
      },
    };

    // endpoint escolhido no preview
    windowConst.CREDERE.src =
      'https://embed.meucredere.com.br/simulations/read';

    const credereJS = document.createElement('script');
    credereJS.type = 'text/javascript';
    credereJS.src = 'https://embed.meucredere.com.br/initialize.js';
    credereJS.id = 'credere-script';
    document.body.append(credereJS);
  };

  const handleStep3 = () => {
    const idLoja = data?.idLoja;
    const idSimulador = data?.idSimulador;
    const documento = data?.documento;

    const condicoes = data?.step2?.settings?.condition_ids;

    windowConst.CREDERE = windowConst.CREDERE || {};
    windowConst.CREDERE.environment = envCredere;
    windowConst.CREDERE.authorization = authCredere;

    windowConst.CREDERE.settings = {
      customers: {
        form: {
          cpf: documento,
          bank_codes: banksToFinancing,
          _proposal: {
            store_id: idLoja,
            simulation_uuid: idSimulador,
            condition_ids: condicoes,
          },
        },
      },
    };

    windowConst.CREDERE.callbacks = {
      'customer:create': function (dataCb) {
        windowConst.alert(
          `Cliente ${dataCb.customer.name} (identificador ${dataCb.customer.id}) criado com sucesso!`
        );

        setData({
          ...data,
          step3: dataCb,
        });
      },
      'customer:update': function (dataCb) {
        windowConst.alert(
          `Cliente ${dataCb.customer.name} (identificador ${dataCb.customer.id}) atualizado com sucesso!`
        );

        setData({
          ...data,
          step3: dataCb,
        });
      },
    };

    // endpoint escolhido no preview
    windowConst.CREDERE.src = 'https://embed.meucredere.com.br/customers/form';

    const credereJS = document.createElement('script');
    credereJS.type = 'text/javascript';
    credereJS.src = 'https://embed.meucredere.com.br/initialize.js';
    credereJS.id = 'credere-script';
    document.body.append(credereJS);
  };

  React.useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }

    document.querySelector('script#credere-script')?.remove();
    document.querySelector('iframe#credere-initializer-iframe')?.remove();

    setTimeout(() => {
      handleExecuteStep();
    }, 1000);
  }, [step]);

  React.useEffect(() => {
    if (idParams) {
      const financing = selectorsRedux?.data?.find((d) => d.id === idParams);

      setData({
        ...data,
        idLoja: financing?.metadata?.idLoja,
        idSimulador: financing?.idFinanciamento,
        documento: financing?.metadata?.documento,
      });

      setStep(financing?.fase);

      setTimeout(() => {
        handleExecuteStep();
      }, 500);
    }

    if (!idParams) {
      setStep(1);
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Button
          onClick={() => history.push(ROUTES.REPORTS.FINANCING)}
          variant="outlined"
        >
          <ArrowBackIcon fontSize="small" />
          Início
        </Button>
      </Box>
      <div
        id="credere"
        style={{
          width: '100%',
          minHeight: '100%',
          backgroundColor: '#fff',
        }}
      ></div>
    </Box>
  );
};

export default FinancingScreen;
