import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import AppLayout from '@giro-pdv-whitelabel/layouts/App.layout';

import OnboardingScreen from './screens/Onboarding.screen';

import useProtectedByAuth from '@giro-pdv-whitelabel/hooks/useProtectedByAuth.hook';

import useJivoChatHook from '@giro-pdv-whitelabel/hooks/useJivoChat.hook';

const OnboardingModule = () => {
  useProtectedByAuth();

  useJivoChatHook('xRbm4yXyhr');

  return (
    <AppLayout>
      <Switch>
        <Route
          exact
          path={ROUTES.ONBOARDING.ROOT}
          component={OnboardingScreen}
        />
      </Switch>
    </AppLayout>
  );
};

export default OnboardingModule;
