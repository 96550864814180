import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/lab/LoadingButton/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';
import useDialogHook from '@giro-pdv-whitelabel/hooks/useDialogRedux.hook';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import clients from '../store/clients';
import sync from '../store/sync';
import tablePayment from '../store/tablePayment';
import fees from '../store/fees';
import dialogPayTerminal from '../store/dialogs/dialogPayTerminal';
import transactions from '../store/transactions';

import websocketUtil from '@giro-pdv-whitelabel/utils/websocket.util';

const ButtonPayActionsComponent = ({
  disabledPay,
  disabledSend,
  onClickPay,
  loading,
}) => {
  const history = useHistory();
  const { user } = useAuthHook();
  const dispatch = useDispatch();
  const { values }: any = useFormikContext();

  const clientsState = useSelector(clients.selector.selectState);
  const syncState = useSelector(sync.selector.selectState);
  const tablePaymentState = useSelector(tablePayment.selector.selectState);
  const feesState = useSelector(fees.selector.selectState);

  const { external_code } = user?.user || {};
  const { external_terminal } = clientsState?.payload || {};

  const totalNumber = Number((values as any)?.total);

  const showDialogTermina = (result, state) => {
    dispatch(
      dialogPayTerminal.action.open({
        amount: totalNumber,
        feesData: feesState?.data,
        typeTax: tablePaymentState?.typeTax,
        return: tablePaymentState?.return,
        retornoGiro: clientsState?.payload?.retornoGiro,
        linkedkey: syncState?.payload?.linkedkey,
        state: values,
        transaction: state,
      })
    );
  };

  const handlePayWithTerminal = () => {
    dispatch(
      transactions.action.servicePost({
        ...values,
        isTerminal: true,
        onSucess: (result, state) => {
          showDialogTermina(result, state);
        },
      })
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={2}>
        <Button
          id="btn-pay-pinpad"
          fullWidth
          variant="contained"
          sx={{
            borderRadius: '10px',
          }}
          loading={loading}
          onClick={onClickPay}
          disabled={disabledPay}
        >
          Pagar
        </Button>
        {external_terminal && (
          <Button
            fullWidth
            variant="contained"
            sx={{
              borderRadius: '10px',
            }}
            loading={loading}
            onClick={handlePayWithTerminal}
            disabled={totalNumber === 0}
          >
            Pagar com Terminal
          </Button>
        )}
      </Box>
      {!!external_code && (
        <Button
          variant="contained"
          loading={loading}
          onClick={() => dispatch(dialogSendPayment.action.open(values))}
          disabled={disabledSend}
          sx={{
            borderRadius: '10px',
          }}
        >
          Enviar link de pagamento
        </Button>
      )}

      <Button
        variant="contained"
        fullWidth
        sx={{
          borderRadius: '10px',
        }}
        loading={loading}
        disabled={totalNumber === 0}
        onClick={() =>
          window.open(`${ROUTES.PDV.SIMULATION}?amount=${totalNumber}`)
        }
        startIcon={<FileDownloadOutlinedIcon />}
      >
        Gerar simulação
      </Button>
    </Box>
  );
};

export default ButtonPayActionsComponent;
