import reducer from './linkedkey.reducer';
import * as constant from './linkedkey.constant';
import * as action from './linkedkey.action';
import * as selector from './linkedkey.selector';
import saga from './linkedkey.saga';
import initialState from './linkedkey.initialState';

export default {
  reducer,
  action,
  constant,
  selector,
  name: constant.NAME,
  saga,
  initialState,
};
