import React from 'react';
import { Container } from '@mui/material';

const CustomContainerComponent = ({ children }) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        width: {
          xs: '100%',
          sm: '516px',
          md: '696px',
          lg: '936px',
          xl: '1116px',
          xxl: '1296px',
        },
      }}
    >
      {children}
    </Container>
  );
};

export default CustomContainerComponent;
