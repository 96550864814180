import React, { useState, useEffect } from 'react';
import { flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useProtectedByAuth from '@giro-pdv-whitelabel/hooks/useProtectedByAuth.hook';
import useIsMobile from '@giro-pdv-whitelabel/hooks/useIsMobile.hook';

import AppLayout from '@giro-pdv-whitelabel/layouts/App.layout';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import DialogSendPaymentComponent from './components/DialogSendPayment.component';
import DialogPayComponent from './components/DialogPay.component';
import DialogPayTerminalComponent from './components/DialogPayTerminal.component';

import AgentScreen from './screens/Agent.screen';
import VehicleScreen from './screens/Vehicle.screen';
import GeralScreen from './screens/Geral.screen';
import AssessoriaScreen from './screens/Assessoria.screen';
import DetranScreen from './screens/Detran.screen';
import TotemScreen from './screens/Totem.screen';
import BilletScreen from './screens/Billet.screen';
import FinancingScreen from './screens/Financing.screen';
import MobileScreen from './screens/Mobile.screen';
import RechargeScreen from './screens/Recharge.screen';
import EducacaoScreen from './screens/Educacao.screen';
import CemiterioScreen from './screens/Cemiterio.screen';
import SimulationScreen from './screens/Simulation.screen';
import CartorioScreen from './screens/Cartorio.screen';
import AboutScreen from './screens/About.screen';
import NewCartorioScreen from './screens/NewCartorio.screen';

import fees from '@giro-pdv-whitelabel/modules/pdv/store/fees';
import clients from '@giro-pdv-whitelabel/modules/pdv/store/clients';
import sync from './store/sync';

import segmentNormalizeConstant from '@giro-pdv-whitelabel/constants/segmentNormalize.constant';

import websocketUtil from '@giro-pdv-whitelabel/utils/websocket.util';

import useJivoChatHook from '@giro-pdv-whitelabel/hooks/useJivoChat.hook';

const PDVModule = () => {
  useJivoChatHook('S8ymwNDX9P');

  const history = useHistory();

  const [connectSocket, setConnectSocket] = useState(false);

  const isMobile = useIsMobile();

  const { signedIn, user, token } = useAuth();

  const dispatch = useDispatch();

  const dispatchRedux = {
    feeServiceGet: flow(fees.action.serviceGet, dispatch),
    clientsServiceGet: flow(clients.action.serviceGet, dispatch),
    syncServicePost: flow(sync.action.servicePost, dispatch),
  };

  const clientsState = useSelector(clients.selector.selectState);
  const syncState = useSelector(sync.selector.selectState);

  useEffect(() => {
    if (!signedIn) {
      return undefined;
    }

    if (signedIn) {
      const hasLocalStorageLinkedState = localStorage.getItem(
        process.env.REACT_APP_SESSION_LINKED_STATE_KEY as string
      );

      if (hasLocalStorageLinkedState) {
        history.push(`${ROUTES.CB.ROOT}/${hasLocalStorageLinkedState}`);

        localStorage.removeItem(
          process.env.REACT_APP_SESSION_LINKED_STATE_KEY as string
        );
      }
    }

    dispatchRedux.feeServiceGet();
    dispatchRedux.clientsServiceGet();

    return () => {
      websocketUtil.disconnectWebSocket();
    };
  }, []);

  useEffect(() => {
    if (clientsState.loading !== false) {
      return undefined;
    }

    dispatchRedux.syncServicePost();
  }, [clientsState.loading]);

  useEffect(() => {
    if (syncState.loading !== false) {
      return undefined;
    }

    (async () => {
      await websocketUtil.connectWebSocket({
        onConnect: () => {
          setConnectSocket(true);
        },
      });
    })();
  }, [syncState.loading]);

  useEffect(() => {
    if (!connectSocket) {
      return undefined;
    }

    const linkedKey = syncState?.payload?.linkedkey;

    websocketUtil.syncCode(linkedKey, (data) => {
      dispatch(sync.action.updateLogSocket(data));
    });
  }, [connectSocket]);

  useEffect(() => {
    const lastLogSocket = syncState.logSocket[syncState.logSocket.length - 1];

    const lastStates = lastLogSocket?.states[lastLogSocket?.states.length - 1];

    if (lastStates?.event === 'pdv-terminal::request-client') {
      websocketUtil.addEvent(syncState?.payload?.linkedkey, {
        event: 'pdv::response-client',
        payload: {
          ...clientsState?.payload,
          token,
        },
      });
    }
  }, [syncState.logSocket]);

  useProtectedByAuth();

  const segment = user?.user?.codes?.segment;

  const rootRouter = {
    [segmentNormalizeConstant.DESPACHANTE]: ROUTES.PDV.AGENT,
    [segmentNormalizeConstant.VEICULOS]: ROUTES.PDV.VEHICLE,
    [segmentNormalizeConstant.GERAL]: ROUTES.PDV.GERAL,
    [segmentNormalizeConstant.CEMITERIO]: ROUTES.PDV.CEMITERIO,
    [segmentNormalizeConstant.EDUCACAO]: ROUTES.PDV.EDUCACAO,
    [segmentNormalizeConstant.PATIO]: ROUTES.PDV.VEHICLE,
    [segmentNormalizeConstant.ACESSORIA]: ROUTES.PDV.ASSESSORIA,
    [segmentNormalizeConstant.DETRAN]: ROUTES.PDV.DETRAN,
    [segmentNormalizeConstant.FINANCIAMENTO]: ROUTES.PDV.FINANCING,
    [segmentNormalizeConstant.RECHARGE]: ROUTES.PDV.RECHARGE,
    [segmentNormalizeConstant.BOLETO]: ROUTES.PDV.BILLET,
    [segmentNormalizeConstant.TOTEM]: ROUTES.PDV.TOTEM,
    [segmentNormalizeConstant.CARTORIO]: ROUTES.PDV.CARTORIO,
  };

  const badSegements = [
    segmentNormalizeConstant.DETRAN,
    segmentNormalizeConstant.BOLETO,
    segmentNormalizeConstant.RECHARGE,
    segmentNormalizeConstant.TOTEM,
  ];

  const segmentsArr = segment?.split(',');

  const firstSegment = segmentsArr?.[0];

  const secondSegment =
    segmentsArr?.filter((e) => !badSegements.some((es) => es == e))?.[0] ||
    null;

  if (!signedIn) {
    return <Redirect to={ROUTES.PDV.ROOT} />;
  }

  const switchDesktop = (
    <Switch>
      <Route exact path={ROUTES.PDV.SIMULATION} component={SimulationScreen} />

      {segment.includes(segmentNormalizeConstant.DESPACHANTE) && (
        <Route exact path={ROUTES.PDV.AGENT} component={AgentScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.VEICULOS) && (
        <Route exact path={ROUTES.PDV.VEHICLE} component={VehicleScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.GERAL) && (
        <Route exact path={ROUTES.PDV.GERAL} component={GeralScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.CARTORIO) && (
        <Route exact path={ROUTES.PDV.CARTORIO} component={CartorioScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.CARTORIO) && (
        <Route
          exact
          path={ROUTES.PDV.NEW_CARTORIO}
          component={NewCartorioScreen}
        />
      )}

      {segment.includes(segmentNormalizeConstant.CARTORIO) && (
        <Route exact path={ROUTES.PDV.ABOUT} component={AboutScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.CEMITERIO) && (
        <Route exact path={ROUTES.PDV.CEMITERIO} component={CemiterioScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.EDUCACAO) && (
        <Route exact path={ROUTES.PDV.EDUCACAO} component={EducacaoScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.PATIO) && (
        <Route exact path={ROUTES.PDV.VEHICLE} component={VehicleScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.ACESSORIA) && (
        <Route
          exact
          path={ROUTES.PDV.ASSESSORIA}
          component={AssessoriaScreen}
        />
      )}

      {segment.includes(segmentNormalizeConstant.DETRAN) && (
        <Route exact path={ROUTES.PDV.DETRAN} component={DetranScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.TOTEM) && (
        <Route exact path={ROUTES.PDV.TOTEM} component={TotemScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.FINANCIAMENTO) && (
        <Route
          exact
          path={`${ROUTES.PDV.FINANCING}`}
          component={FinancingScreen}
        />
      )}

      {segment.includes(segmentNormalizeConstant.FINANCIAMENTO) && (
        <Route
          exact
          path={`${ROUTES.PDV.FINANCING}/:id`}
          component={FinancingScreen}
        />
      )}

      {segment.includes(segmentNormalizeConstant.BOLETO) && (
        <Route exact path={ROUTES.PDV.BILLET} component={BilletScreen} />
      )}

      {segment.includes(segmentNormalizeConstant.RECHARGE) && (
        <Route exact path={ROUTES.PDV.RECHARGE} component={RechargeScreen} />
      )}

      <Redirect to={rootRouter[secondSegment || firstSegment]} />
    </Switch>
  );

  const switchMobile = (
    <Switch>
      <Route exact path={ROUTES.PDV.MOBILE} component={MobileScreen} />

      <Redirect to={ROUTES.PDV.MOBILE} />
    </Switch>
  );

  return (
    <AppLayout>
      {isMobile ? switchMobile : switchDesktop}

      <DialogSendPaymentComponent />
      <DialogPayComponent />
      <DialogPayTerminalComponent />
    </AppLayout>
  );
};

export default PDVModule;
