import React from 'react';
import { flow } from 'lodash';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useIsMobileHook from '@giro-pdv-whitelabel/hooks/useIsMobile.hook';

import HeaderComponent from '@giro-pdv-whitelabel/components/Header.component';
import Container from '@giro-pdv-whitelabel/components/CustomContainer.component';

import pinpad from '@giro-pdv-whitelabel/modules/pdv/store/pinpad';
import menu from '@giro-pdv-whitelabel/store/menu';
import useIsTotemHook from '@giro-pdv-whitelabel/hooks/useTotem.hook';
import HeaderEvoraComponent from '@giro-pdv-whitelabel/components/HeaderEvora.component';

import auth from '@giro/shared-store/auth';
import fees from '@giro-pdv-whitelabel/modules/pdv/store/fees';
import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

const AppLayout = ({ children }) => {
  const history = useHistory();

  const isMobile = useIsMobileHook();
  const isTotem = useIsTotemHook();

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateStatus: flow(pinpad.action.updateStatus, dispatch),
    open: flow(menu.action.open, dispatch),
    close: flow(menu.action.close, dispatch),
    unsetToken: flow(auth.action.unsetToken, dispatch),
  };

  const selectorRedux = {
    status: useSelector(pinpad.selector.selectStatus),
    menuVisible: useSelector(menu.selector.selectVisible),
    feesData: useSelector(fees.selector.selectData),
  };

  const handleToggleSide = () => {
    if (selectorRedux.menuVisible) {
      dispatchRedux.close();
    }
    if (!selectorRedux.menuVisible) {
      dispatchRedux.open({});
    }
  };

  const hasPix = selectorRedux.feesData?.some((fd) => fd.cdTipoTaxa === 3);

  const alertObj = {
    connecting: {
      message: 'Conectando-se ao pinpad...',
      props: {
        severity: 'warning',
      },
    },
    error: {
      message: 'Pinpad não encontrado',
      props: {
        severity: 'error',
        action: (
          <Button
            color="inherit"
            size="small"
            onClick={() => window.location.reload()}
          >
            Tentar novamente
          </Button>
        ),
      },
    },
    connected: {
      message: 'Pinpad conectado',
      props: {
        severity: 'success',
        onClose: () => dispatchRedux.updateStatus(null),
      },
    },
  };

  const menuDown = [
    {
      label: 'Configurações',
      onClick: () => history.push(ROUTES.CONFIGURATIONS),
      icon: (
        <svg
          width="16"
          height="16"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href="/feather-sprite.svg#settings" />
        </svg>
      ),
    },
    {
      label: 'Sair',
      onClick: () => dispatchRedux.unsetToken(),
      icon: (
        <svg
          width="16"
          height="16"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href="/feather-sprite.svg#log-out" />
        </svg>
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column">
      {selectorRedux.status !== null && (
        <Alert {...alertObj?.[selectorRedux.status].props}>
          {alertObj[selectorRedux.status]?.message}
        </Alert>
      )}

      <Box id="header-app">
        {!isTotem ? (
          <HeaderComponent
            sideOpen={selectorRedux.menuVisible}
            handleToggleSide={handleToggleSide}
          />
        ) : (
          <HeaderEvoraComponent
            sideOpen={selectorRedux.menuVisible}
            handleToggleSide={handleToggleSide}
          />
        )}
      </Box>

      <Box
        py={4}
        flex={1}
        minHeight="calc(100vh - 372px)"
        id="containerScroll"
        sx={{
          overflowY: 'auto',
          '@media print': {
            height: '100% !important',
          },
        }}
      >
        <Container>{children}</Container>
      </Box>

      <Box
        bgcolor="grey.100"
        id="footer-app"
        borderTop="1px solid"
        borderColor="grey.300"
      >
        <Container>
          <Box
            height={100}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center">
              <Typography variant="trasso_heading_small" color="grey.500">
                Bandeiras aceitas:
              </Typography>
              <Box display="grid" gridTemplateColumns="1fr">
                {hasPix && <img src="/brands-pix.png" />}
                {!hasPix && <img src="/brands.png" />}
              </Box>
            </Box>
            <Box color="grey.500" display="flex" gap={2}>
              {menuDown.map((item, index) => (
                <Button
                  key={index}
                  variant="text"
                  size="small"
                  color="inherit"
                  onClick={() => item.onClick()}
                  startIcon={item.icon}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AppLayout;
