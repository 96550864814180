import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useHistory } from 'react-router-dom';
import { flow } from 'lodash';
import { IconButton } from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';

import useIsCreaHook from '@giro-pdv-whitelabel/hooks/useIsCrea.hook';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';
import useIsMobile from '@giro-pdv-whitelabel/hooks/useIsMobile.hook';

import Logo from '@giro/shared-components/Logo.component';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';
import segmentNormalizeConstant from '@giro-pdv-whitelabel/constants/segmentNormalize.constant';
import { menuSidebarConstant } from './Sidebar.component';

const menuPDV = (segment) => [
  {
    label: 'Despachante',
    route: ROUTES.PDV.AGENT,
    visible: segment.includes(segmentNormalizeConstant.DESPACHANTE),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#file-text" />
      </svg>
    ),
  },
  {
    label: 'Veículo',
    route: ROUTES.PDV.VEHICLE,
    visible: segment.includes(segmentNormalizeConstant.VEICULOS),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#truck" />
      </svg>
    ),
  },
  {
    label: 'Geral',
    route: ROUTES.PDV.GERAL,
    visible: segment.includes(segmentNormalizeConstant.GERAL),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#sidebar" />
      </svg>
    ),
  },
  {
    label: 'Educação',
    route: ROUTES.PDV.EDUCACAO,
    visible: segment.includes(segmentNormalizeConstant.EDUCACAO),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#book-open" />
      </svg>
    ),
  },
  {
    label: 'Pátio',
    route: ROUTES.PDV.VEHICLE,
    visible: segment.includes(segmentNormalizeConstant.PATIO),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#truck" />
      </svg>
    ),
  },
  {
    label: 'Assessoria',
    route: ROUTES.PDV.ASSESSORIA,
    visible: segment.includes(segmentNormalizeConstant.ACESSORIA),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#hash" />
      </svg>
    ),
  },
];

const menuReports = ({ isCrea, segment }) => [
  {
    label: 'EC',
    route: ROUTES.REPORTS.EC,
    visible: !isCrea,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#file-text" />
      </svg>
    ),
  },
  {
    label: 'Financiamento',
    route: ROUTES.REPORTS.FINANCING,
    visible:
      !isCrea && segment?.includes(segmentNormalizeConstant.FINANCIAMENTO),
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#file-text" />
      </svg>
    ),
  },
  {
    label: 'Crea',
    route: ROUTES.REPORTS.CREA,
    visible: isCrea,
    icon: (
      <svg
        width="16"
        height="16"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use href="/feather-sprite.svg#file-text" />
      </svg>
    ),
  },
];

const HeaderEvoraComponent = ({ handleToggleSide = () => {}, sideOpen }) => {
  const isMobile = useIsMobile();
  const { user } = useAuthHook();
  const history = useHistory();
  const { pathname } = useLocation();
  const isCrea = useIsCreaHook();

  const isCurrentRoute = (route) => pathname === route;

  const isSubPdv = menuSidebarConstant.map((ms) => ms.route).includes(pathname);

  const currentModule = isSubPdv ? 'default' : pathname.split('/')[1];

  const menuToRender = {
    default: flow(() => {}),
    pdv: flow(() => user?.user?.codes?.segment, menuPDV),
    reports: flow(
      () => ({ isCrea, segment: user?.user?.codes?.segment }),
      menuReports
    ),
  };

  const menuArr = (menuToRender[currentModule] || menuToRender.default)() || [];
  const menuArrOnlyVisible = menuArr?.filter((e) => e.visible);

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="primary.main"
      className="header-container"
    >
      <Box
        display="grid"
        gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        height="200px"
        alignContent="center"
        px={4}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={4}
          width="100%"
          justifyContent={isMobile ? 'space-between' : undefined}
        >
          <Box p={1} bgcolor="white" borderRadius={1}>
            <Logo variant="evora" style={{ width: 200 }} />
          </Box>
        </Box>

        {!isMobile && (
          <Box justifySelf="end" alignSelf="center">
            <MenuList sx={{ display: 'flex', gap: 4 }} disablePadding>
              {menuArrOnlyVisible.map((el) => (
                <MenuItem
                  onClick={() => history.push(el.route)}
                  key={el.label}
                  disableGutters
                  sx={{ p: 0 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    color={isCurrentRoute(el.route) ? 'white' : '#724C95'}
                    flexDirection="column"
                    sx={{
                      '&:hover': {
                        color: 'white',

                        '.header-decoration': {
                          backgroundColor: 'trasso.pink',
                        },
                      },
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      {el.icon}
                      <Typography variant="trasso_button">
                        {el.label}
                      </Typography>
                    </Box>
                    <Box
                      className="header-decoration"
                      height={'2px'}
                      bgcolor={
                        isCurrentRoute(el.route) ? 'trasso.pink' : 'transparent'
                      }
                      width="100%"
                    />
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HeaderEvoraComponent;
