import { Switch, Route } from 'react-router-dom';

import AppLayout from '@giro-pdv-whitelabel/layouts/App.layout';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import SetupScreen from './screens/Setup.screen';

const SetupModule = () => {
  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.SETUP.ROOT} component={SetupScreen} />
      </Switch>
    </AppLayout>
  );
};

export default SetupModule;
