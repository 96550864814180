import { takeLatest, select, call, put } from 'redux-saga/effects';
import format from 'date-fns/format';
import { pickBy } from 'lodash';

import currentStore from '.';

import getApiReportsService from '@giro/shared-services/reports/getApiPdvTransactions.service';

function* handleFetchAPI(action) {
  const { payload } = action;

  const filters = yield select(currentStore.selector.selectFilters);

  const newFilters = {
    ...filters,
    dateStart: format(new Date(filters?.dateStart), 'yyyy-MM-dd'),
    dateEnd: format(new Date(filters?.dateEnd), 'yyyy-MM-dd'),
  };

  yield put(currentStore.action.fetchStart());

  const [success, body] = yield call(
    getApiReportsService,
    pickBy(newFilters) as any
  );

  if (!success) {
    return yield put(currentStore.action.fetchError(body));
  }

  return yield put(currentStore.action.fetchEnd(body));
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.GET,
    handleFetchAPI
  );
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.UPDATE.FILTERS,
    handleFetchAPI
  );
}

export default {
  watch,
};
