import React from 'react';
import Cookies from 'js-cookie';

const LogoffScreen = () => {
  React.useEffect(() => {
    Cookies.remove(process.env.REACT_APP_COOKIE_KEY_TOKEN, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });

    Cookies.remove(process.env.REACT_APP_COOKIE_IDRC_KEY_TOKEN, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });

    (window as any).location.href = process.env.REACT_APP_CB_LOGIN_URL;
  }, []);

  return <div>Carregando...</div>;
};

export default LogoffScreen;
