import mock from '@giro-pdv-whitelabel/mocks/state.mock.json';

const initialState = {
  amount: 0,
  loading: null,
  installmentSelected: null,
  typeTax: 'client',
  typeTaxCredit: 'client',
  return: 0,
  rows: [],
};

// export default mock.pdv.tablePayment;
export default initialState;
