import { useEffect } from 'react';
import { flow } from 'lodash';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import useProtectedByAuth from '@giro-pdv-whitelabel/hooks/useProtectedByAuth.hook';

import AppLayout from '@giro-pdv-whitelabel/layouts/App.layout';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import ReceiptScreen from './screens/Receipt.screen';
import ReceiptThermalScreen from './screens/ReceiptThermal.screen';

const ReceiptModule = () => {
  useProtectedByAuth();

  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.RECEIPT.ROOT} component={ReceiptScreen} />
        <Route
          exact
          path={`${ROUTES.RECEIPT.ID(':id')}`}
          component={ReceiptThermalScreen}
        />

        <Redirect to={ROUTES.RECEIPT.ROOT} />
      </Switch>
    </AppLayout>
  );
};

export default ReceiptModule;
