import { Switch, Route } from 'react-router-dom';

import useProtectedByAuth from '@giro-pdv-whitelabel/hooks/useProtectedByAuth.hook';

import CbScreen from './screens/Cb.screen';

import AppLayout from '@giro-pdv-whitelabel/layouts/App.layout';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

const CbModule = () => {
  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.CB.ID()} component={CbScreen} />
      </Switch>
    </AppLayout>
  );
};

export default CbModule;
