import reducer from './configuration.reducer';
import * as selector from './configuration.selector';
import * as constant from './configuration.constant';
import subscriber from './configuration.subscriber';
import saga from './counfiguration.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
