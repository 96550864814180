import React from 'react';
import { Box, TextField as TextFieldMaterial, Typography } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InputAdornment from '@mui/material/InputAdornment';

import TextFieldLabelComponent from '@giro-pdv-whitelabel/components/TextFieldLabel.component';

import TextFieldLabelKeyboardComponent from '@giro-pdv-whitelabel/components/TextFieldLabelKeyboard.component';

import FieldLabelFormik from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';
import FieldDocumentComponent from '@giro/shared-components/Fields/FieldDocument.component';

import messages from '@giro/shared-constants/messagesSchema.constant';

export const validationSchema = Yup.object({
  placa: Yup.string()
    .required(messages.REQUIRED)
    .min(7, messages.MIN)
    .max(7, messages.MAX)
    .matches(
      /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/,
      'Você precisa digitar uma placa válida'
    ),
  document_number: Yup.string().required(messages.REQUIRED),
});

const FormPlacaDocumentComponent = (props) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" {...props} gap={4}>
      <TextFieldLabelKeyboardComponent
        name="placa"
        label="Placa"
        size="small"
      />
    </Box>
  );
};

export default FormPlacaDocumentComponent;
