import { Client } from '@hapi/nes/lib/client';

const websocketUtil = (function () {
  let client;

  const disconnectWebSocket = () => {
    if (client) {
      client.disconnect();

      console.log('ws disconnected');
    }
  };

  const connectWebSocket = async function ({
    onConnect,
    onError,
  }: {
    onConnect?: () => void;
    onError?: () => void;
  }) {
    // https://api.pdv.giropagamentos.com.br/
    client = new Client('wss://api.sipe.registrocivil.org.br');

    try {
      await client.connect();

      if (onConnect) {
        onConnect();
      }
    } catch (e) {
      if (onError) {
        onError();
      }
    }
  };

  const channels = {};

  const syncCode = (codeLinkedKey, onSubscribe) => {
    const channel = `/${codeLinkedKey}/states`;
    if (!channels[channel]) {
      channels[channel] = true;
      subscribeToChannel(channel, onSubscribe);
    }
  };

  const addEvent = (codeLinkedKey, payload) =>
    client.request({
      method: 'POST',
      path: `/api/linkstates/${codeLinkedKey}`,
      payload,
    }); // chama a api e passa evento pro pdv modificar algo na transação

  const subscribeToChannel = function (channel, onSubscribe) {
    client.subscribe(channel, (message, flags) => {
      onSubscribe(message);
    });

    console.log('Subscribed to:', channel);
  };

  return {
    connectWebSocket,
    disconnectWebSocket,
    syncCode,
    addEvent,
  };
})();

export default websocketUtil;
