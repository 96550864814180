import React from 'react';
import { flow } from 'lodash';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Box from '@mui/material/Box';
import { BsPlusSlashMinus } from 'react-icons/bs';
import { MdOutlineAttachMoney } from 'react-icons/md';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';

import ROUTES from '@giro-pdv-whitelabel/constants/routes.constant';

import dialogReceipt from '@giro-pdv-whitelabel/store/dialogReceipt';

import reports from '../store/reportsFinancing';
import { IconContext } from 'react-icons/lib';

export default function TableReportCreaComponent() {
  const history = useHistory();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const dispatch = useDispatch();

  const selectorRedux = {
    data: useSelector(reports.selector.selectData),
    loading: useSelector(reports.selector.selectLoading),
  };

  const dispatchRedux = {
    updateFilters: flow(reports.action.updateFilters, dispatch),
    resetState: flow(reports.action.resetState, dispatch),
    receiptOpen: flow(dialogReceipt.action.open, dispatch),
  };

  React.useEffect(() => {
    dispatchRedux.updateFilters({});

    // return () => {
    //   dispatchRedux.resetState();
    // };
  }, []);

  const indexOfLast = currentPage * limit;
  const indexOfFirst = indexOfLast - limit;
  const currentData = (selectorRedux.data || []).slice(
    indexOfFirst,
    indexOfLast
  );

  const currentDataFormatMetadata = currentData.map((elm) => ({
    ...elm,
    name: elm.metadata?.step1?.data?.lead?.name,
  }));

  const renderTableCell = (el, idx, row) => {
    const isDate = ['createdAt', 'updatedAt'];
    const isAmount = ['vlTransacao', 'vlLiberado'];
    const isCustom = ['receipt'];

    if (isCustom.includes(el)) {
      if (el === 'receipt') {
        return (
          <TableCell
            component="th"
            scope="row"
            key={`${el}-${idx}`}
            width={200}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push(`${ROUTES.PDV.FINANCING}/${row.id}`)}
            >
              Retornar à simulação
            </Button>
          </TableCell>
        );
      }
    }

    if (isDate.includes(el)) {
      return (
        <TableCell component="th" scope="row" key={`${el}-${idx}`}>
          <Typography
            variant="trasso_body_extra_small"
            color="trasso.purple.100"
          >
            {format(new Date(row[el].replace('Z', '')), 'dd/MM/yyyy HH:mm')}
          </Typography>
        </TableCell>
      );
    }

    if (isAmount.includes(el)) {
      return (
        <TableCell component="th" scope="row" key={`${el}-${idx}`}>
          <Typography
            variant="trasso_body_extra_small"
            color="trasso.purple.100"
          >
            {Number(row[el]).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </TableCell>
      );
    }

    return (
      <TableCell component="th" scope="row" key={`${el}-${idx}`}>
        <Typography variant="trasso_body_extra_small" color="trasso.purple.100">
          {row[el]}
        </Typography>
      </TableCell>
    );
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="300px"
      position="relative"
    >
      {selectorRedux?.data?.length > 0 && !selectorRedux.loading && (
        <Box p={2}>
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              sx={{ minWidth: { xl: '100%', md: '1300px' } }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  {[
                    'Nome',
                    'Etapa da Simulação',
                    'Data de criação',
                    'Útima modificação',
                    '',
                  ].map((el, idx) => (
                    <TableCell key={`row-${idx}`}>
                      <Typography
                        variant="trasso_heading_extra_small"
                        color="trasso.purple.100"
                      >
                        {el}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentDataFormatMetadata.map((row) => (
                  <TableRow
                    key={row.dtTransacao}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {[
                      'name',
                      'fase',
                      'createdAt',
                      'updatedAt',
                      'receipt',
                      // 'idTransacao',
                      // 'dtTransacao',
                      // 'vlTransacao',
                      // 'vlLiberado',
                      // 'tpPagamento',
                      // 'qtdParcelas',
                      // 'status',
                      // 'receipt',
                    ].map((el, idx) => renderTableCell(el, idx, row))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    count={selectorRedux.data?.length || 0}
                    rowsPerPage={limit}
                    page={currentPage - 1}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={(ev, nextPage) =>
                      setCurrentPage(nextPage + 1)
                    }
                    onRowsPerPageChange={(ev: any) => setLimit(ev.target.value)}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )}

      {selectorRedux?.data?.length === 0 && !selectorRedux.loading && (
        <Typography>Nenhum dado encontrado para essa pesquisa.</Typography>
      )}

      {selectorRedux.loading && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          top={0}
          left={0}
          bgcolor="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
