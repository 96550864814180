import { takeLatest, select, call, put } from 'redux-saga/effects';
import format from 'date-fns/format';

import currentStore from '.';

import getApiClientsOnboardingsService from '@giro/shared-services/pdv/getApiClientsOnboardings.service';

function* handleFetchAPI(action) {
  yield put(currentStore.action.fetchStart());

  const [success, body] = yield call(getApiClientsOnboardingsService);

  if (!success) {
    return yield put(currentStore.action.fetchError(body));
  }

  return yield put(currentStore.action.fetchEnd(body));
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.GET,
    handleFetchAPI
  );
}

export default {
  watch,
};
