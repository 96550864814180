import qs from 'qs';

import fetch from '@giro/shared-utils/fetch.util';

type FiltersType = {
  offset?: string;
  limit?: string;
};

export default async function getApiUserkeysService(filters: FiltersType) {
  const filtersStringfy = qs.stringify(filters);

  const url = ['userkeys', filtersStringfy].filter(Boolean).join('?');

  return fetch(url, {
    method: 'GET',
    ms: 'PDV',
    auth: true,
  });
}
