import React from 'react';
import { Formik, Field } from 'formik';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { flow } from 'lodash';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { format, addMinutes, isBefore, startOfDay } from 'date-fns';
import { v4 } from 'uuid';
import NumberFormat from 'react-number-format';

import FieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';
import { FieldCurrencyComponentDigits } from '@giro/shared-components/Fields/FieldCurrency.component';
import UpdateAmountComponent from '../components/UpdateAmount.component';
import TextFieldLabelComponent from '@giro-pdv-whitelabel/components/TextFieldLabel.component';
import ButtonPayActionsComponent from '../components/ButtonPayActions.component';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import PDVLayout from '../layouts/PDV.layout';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';
import domain from '../store/debts/domain';
import debts from '../store/debts/debts';
import toaster from '@giro/shared-store/toaster';

const maskConveniado =
  '#####.##### #####.###### #####.###### # ###############';

const maskTribute = '###########-# ###########-# ###########-# ###########-#';

function formatDate(date, formatter) {
  return format(addMinutes(date, date.getTimezoneOffset()), formatter);
}

const maskDocument = (document) => {
  const stringDocument = String(document);

  if (stringDocument.length === 11) {
    return stringDocument.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
      '$1.$2.$3-$4'
    );
  }
  if (stringDocument.length === 14) {
    return stringDocument.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      '$1.$2.$3/$4-$5'
    );
  }
};

const ItemBillet = ({ label, value, ...restProps }) => (
  <Box display="flex" flexDirection="column" {...restProps}>
    <Typography variant="subtitle2" color="gray.400">
      {label}
    </Typography>
    <Typography variant="subtitle1" color="primary.main">
      {value}
    </Typography>
  </Box>
);

const TYPES = {
  PLACARENAVAM: 0,
  RENAVAM: 1,
  DOCUMENTO: 2,
};

const FormBillet = () => {
  const formikRef: any = React.useRef();
  const dispatch = useDispatch();
  const { user } = useAuthHook();

  const [isTribute, setIsTribute] = React.useState(false);
  const [key, setKey] = React.useState(v4());

  const dispatchRedux = {
    serviceGet: flow(domain.action.serviceGet, dispatch),
    resetDebts: flow(debts.action.resetState, dispatch),
    updateFilters: flow(debts.action.updateFilters, dispatch),
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
    select: flow(debts.action.select, dispatch),
    unselect: flow(debts.action.unselect, dispatch),
    show: flow(toaster.action.show, dispatch),
  };

  const selectorRedux = {
    selectedDebts: useSelector(debts.selector.selectSelected),
    dataDebts: useSelector(debts.selector.selectData),
    domainData: useSelector(domain.selector.selectData),
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
    loadingDebts: useSelector(debts.selector.selectLoading),
    errorDebts: useSelector(debts.selector.selectError),
  };

  const billet = selectorRedux?.dataDebts?.results?.[0];

  const fetchedDebts = selectorRedux.loadingDebts === false && billet;

  React.useEffect(() => {
    if (selectorRedux.errorDebts === false && !billet) {
      dispatchRedux.show({
        message: 'Não encontramos nenhum resultado para o boleto procurado',
        variant: 'error',
      });
    }
  }, [selectorRedux.errorDebts]);

  React.useEffect(() => {
    return () => {
      dispatchRedux.resetDebts();
    };
  }, []);

  const initialValues = {
    barcode: null,
    integration_id: 'f34fd740-9731-446a-91a5-90e05f5a7226',
    total: null,
    fees: 0,
    segment: '5',
    type_tax: 'client',
  };

  const onSubmit = () => {};

  React.useEffect(() => {
    if (selectorRedux.dataDebts?.results) {
      const currentBillet = selectorRedux.dataDebts?.results?.[0];

      formikRef.current.setFieldValue(
        'total',
        currentBillet?.amount + Number(formikRef.current?.values?.fees || 0)
      );
    }
  }, [selectorRedux.dataDebts]);

  React.useEffect(() => {
    if (billet?.barcode) {
      const first = billet.barcode[0];
      setIsTribute(first === '8');
    }
  }, [billet?.barcode]);

  const isDateInPast = (date) => {
    const now = startOfDay(new Date());
    const formattedDate = new Date(date.split('T')[0].replace(/-/g, '/'));

    const verifyDates = isBefore(formattedDate, now);

    return verifyDates;
  };

  const beneficiaryTribute = billet?.payer?.name;
  const beneficiryNotTribute = [
    billet?.beneficiary?.document?.number &&
      maskDocument(billet?.beneficiary?.document?.number),
    billet?.beneficiary?.name,
  ]
    .filter(Boolean)
    .join(' - ');

  const beneficiary = isTribute ? beneficiaryTribute : beneficiryNotTribute;

  const contributor = isTribute
    ? null
    : [
        billet?.payer?.document?.number &&
          maskDocument(billet?.payer?.document?.number),
        billet?.payer?.name,
      ]
        .filter(Boolean)
        .join(' - ');

  const isBilletDued = isDateInPast(
    billet?.due_date || new Date().toISOString()
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      key={key}
    >
      {({ values, setFieldValue, handleReset }) => {
        return (
          <>
            <UpdateAmountComponent />
            <Box display="flex" flexDirection="column" gap={4}>
              <Box display="flex" flexDirection="column" gap={4}>
                <Field name="barcode">
                  {({
                    field,
                    form: { setFieldValue, setFieldTouched },
                    meta: { error, touched },
                  }) => (
                    <NumberFormat
                      {...({ size: 'small' } as any)}
                      customInput={TextFieldLabelComponent}
                      multiline
                      fullWidth
                      disabled={fetchedDebts}
                      label="Código de barras"
                      format={
                        String(field.value)?.[0] === '8'
                          ? maskTribute
                          : maskConveniado
                      }
                      value={field.value}
                      error={touched && !!error}
                      helperText={touched && !!error && error}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 'auto',
                          color: 'trasso.purple.100',
                          '& fieldset': {
                            borderColor: 'trasso.gray.40',
                          },
                        },
                      }}
                      onBlur={() => {
                        setFieldTouched(field.name, true);
                      }}
                      onValueChange={(values: any) =>
                        setFieldValue(field.name, values.value)
                      }
                    />
                  )}
                </Field>

                <Typography variant="subtitle2" color="primary">
                  * Não é permitido o pagamento de fatura de cartão de crédito.
                </Typography>

                {fetchedDebts && (
                  <Box
                    width="100%"
                    border="1px solid black"
                    borderColor="gray.200"
                    p={4}
                    borderRadius={2}
                    gap={2}
                    display="grid"
                    gridTemplateColumns="1fr"
                  >
                    {isBilletDued && (
                      <Typography color="red" fontWeight={800}>
                        BOLETO VENCIDO
                      </Typography>
                    )}

                    <ItemBillet label="Descrição" value={billet?.description} />
                    <Divider />

                    <ItemBillet
                      label="Data de vencimento"
                      value={formatDate(
                        new Date(billet?.due_date),
                        'dd/MM/yyyy'
                      )}
                    />
                    <Divider />

                    {billet?.bank?.id && (
                      <>
                        <ItemBillet
                          label="Banco destinatário"
                          value={`${billet?.bank?.id} - ${billet?.bank?.name}`}
                        />
                        <Divider />
                      </>
                    )}

                    <ItemBillet label="Beneficiário" value={beneficiary} />
                    <Divider />

                    {!isTribute && (
                      <>
                        <ItemBillet label="Contribuinte" value={contributor} />
                        <Divider />
                      </>
                    )}

                    <ItemBillet
                      label="Valor do documento"
                      value={Number(billet.amount).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    />
                  </Box>
                )}

                {fetchedDebts && (
                  <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                    <FieldCurrencyComponentDigits
                      customInput={TextFieldLabelComponent}
                      size="small"
                      name="fees"
                      label="Honorários"
                      onChange={(val) =>
                        setFieldValue(
                          'total',
                          Number(
                            selectorRedux.dataDebts?.results?.[0]?.amount || 0
                          ) + Number(val)
                        )
                      }
                    />

                    {values.type_tax === 'client' && (
                      <Box display="flex" flexDirection="column">
                        <Typography variant="trasso_heading_small">
                          R
                        </Typography>
                        <Slider
                          step={2}
                          sx={{
                            mt: 4,
                            '& .MuiSlider-track': {
                              border: 'none',
                              backgroundColor: 'trasso.gray.40',
                            },
                            '& .MuiSlider-thumb': {
                              height: 24,
                              width: 24,
                              backgroundColor: '#fff',
                              border: '4px solid currentColor',
                            },
                            '& .MuiSlider-valueLabel': {
                              lineHeight: 1.2,
                              fontSize: 12,
                              background: 'unset',
                              padding: 0,
                              width: 32,
                              height: 32,
                              backgroundColor: 'trasso.purple.60',
                            },
                          }}
                          color="secondary"
                          size="small"
                          defaultValue={0}
                          aria-label="Small"
                          valueLabelDisplay="auto"
                          max={12}
                          value={selectorRedux.return}
                          onChange={(ev, value) => {
                            dispatchRedux.updateReturn(value);
                          }}
                          marks={[
                            {
                              value: 0,
                              label: (
                                <Typography
                                  variant="trasso_body_small"
                                  color="trasso.purple.100"
                                >
                                  0
                                </Typography>
                              ),
                            },
                            {
                              value: 12,
                              label: (
                                <Typography
                                  variant="trasso_body_small"
                                  color="trasso.purple.100"
                                >
                                  12
                                </Typography>
                              ),
                            },
                          ]}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>

              {!fetchedDebts && (
                <Button
                  variant="outlined"
                  loading={selectorRedux.loadingDebts}
                  disabled={!values?.barcode}
                  onClick={() => {
                    const newValues: any = { ...values };

                    dispatchRedux.updateFilters({
                      barcode: values?.barcode,
                      integration_id: values?.integration_id,
                    });
                  }}
                >
                  Pesquisar boleto
                </Button>
              )}

              {fetchedDebts && (
                <Box display="grid" gridTemplateColumns="1fr" gap={4}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      dispatchRedux.resetDebts();

                      setKey(v4());

                      document
                        .querySelector('#containerScroll')
                        ?.scrollTo(0, 0);
                    }}
                  >
                    Limpar pesquisa
                  </Button>
                  <ButtonPayActionsComponent
                    loading={selectorRedux.transactionsLoading}
                    onClickPay={() =>
                      dispatchRedux.transactionsServicePost({
                        ...values,
                      })
                    }
                    disabledPay={
                      selectorRedux.transactionsLoading ||
                      selectorRedux.installmentSelected === null ||
                      Number((values as any).total) === 0 ||
                      isBilletDued
                    }
                    disabledSend={Number((values as any).total) === 0}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

const BilletScreen = () => {
  return (
    <PDVLayout>
      <FormBillet />
    </PDVLayout>
  );
};

export default BilletScreen;
