import Cookie from 'js-cookie';

const initialState = {
  data: null,
  loading: null,
  error: [null, null],
  token_idrc: Cookie.get(process.env.REACT_APP_COOKIE_IDRC_KEY_TOKEN) || null,
};

export default initialState;
