import React from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  Divider,
  Link,
  Button,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form, Formik } from 'formik';
import Dialog from '@mui/material/Dialog';
import * as yup from 'yup';
import pickBy from 'lodash/pickBy';
import { useDispatch } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import banksConstant from '@giro-pdv-whitelabel/constants/banks.constant';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import useFetchHook from '@giro-pdv-whitelabel/hooks/useFetch.hook';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import FieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';
import FieldDocumentComponent from '@giro/shared-components/Fields/FieldDocument.component';
import TextFieldLabelComponent from '@giro-pdv-whitelabel/components/TextFieldLabel.component';

import patchApiSettingsService from '@giro/shared-services/pdv/patchApiSettings.service';
import getApiSettingsService from '@giro/shared-services/pdv/getApiSettings.service';
import getApiUserkeysService from '@giro/shared-services/pdv/getApiUserkeys.service';
import deleteApiUserkeysIdService from '@giro/shared-services/pdv/deleteApiUserkeysId.service';
import postApiUserkeysService from '@giro/shared-services/pdv/postApiUserkeys.service';

import settings from '../store/settings';

const ConfigurationScreen = () => {
  const dispatch = useDispatch();

  const [modalCreate, setModalCreate] = React.useState(false);

  const [dataPostKey, setDataPostKey] = React.useState<any>(null);

  const { user } = useAuthHook();

  const { data: dataSettings, loading: loadingSettings } = useFetchHook(
    getApiSettingsService,
    {
      requestOnMount: true,
    }
  );

  const {
    data: dataUserkeys,
    loading: loadingUserkeys,
    handleFetch: handleFetchUserkeys,
  } = useFetchHook(getApiUserkeysService, {
    requestOnMount: true,
  });

  const {
    loading: loadingDeleteUserkeys,
    handleFetch: handleFetchDeleteUserkeys,
  } = useFetchHook(deleteApiUserkeysIdService);

  React.useEffect(() => {
    if (modalCreate) {
      setDataPostKey(null);
    }
  }, [modalCreate]);

  const handleCloseDialogNew = () => {
    setModalCreate(false);
  };

  const dialogDetails = (
    <Dialog
      open={modalCreate}
      scroll="body"
      onClose={() => {
        handleCloseDialogNew();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {true && (
        <Box display="flex" flexDirection="column" p={2} gap={1.5} width={400}>
          {!dataPostKey && (
            <Formik
              initialValues={{ name: '' }}
              validationSchema={yup.object({
                name: yup.string().required('Campo obrigatório'),
              })}
              validateOnMount
              onSubmit={async (values) => {
                const [success, data] = await postApiUserkeysService(
                  values as any
                );

                if (success) {
                  handleFetchUserkeys();
                }

                setDataPostKey(data);

                return success;
              }}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap={1.5}>
                    <FieldLabelFormikComponent
                      size="small"
                      name="name"
                      label="Nome"
                    />

                    <Box display="flex" flexDirection="row" gap={1.5}>
                      <Box width="100%" color="grey.700">
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            handleCloseDialogNew();
                          }}
                          color="inherit"
                          fullWidth
                        >
                          Fechar
                        </Button>
                      </Box>
                      <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={Object.keys(errors).length > 0}
                      >
                        Criar
                      </LoadingButton>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          )}

          {dataPostKey && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gap={1.5}
              alignItems="center"
            >
              <Typography variant="subtitle1" fontWeight={600}>
                Chave gerada
              </Typography>
              <Typography align="center" variant="trasso_body_small">
                Antes de fechar essa janela, copie a chave abaixo e guarde em
                local seguro. Essa chave não será exibida novamente.
              </Typography>
              <Box
                p={1}
                border="1px solid transparent"
                borderColor="grey.300"
                width="100%"
                borderRadius={1}
                display="flex"
                flexDirection="column"
              >
                <Typography
                  variant="trasso_body_medium"
                  color="grey.900"
                  align="center"
                >
                  {dataPostKey?.apikey}
                </Typography>
              </Box>
              <Box width="100%" color="grey.700">
                <Button
                  onClick={() => {
                    handleCloseDialogNew();
                  }}
                  color="inherit"
                  fullWidth
                >
                  Fechar
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}

      {false && (
        <Box
          width={500}
          height={500}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );

  const isAdmin = user?.user?.codes?.idRole === 4;

  const bankObj = (bankCode) => {
    return banksConstant.find((bank: any) => bank.Banco === bankCode);
  };

  const setValeArr = (
    field,
    value,
    action: 'add' | 'remove',
    { setFieldValue, values }
  ) => {
    const arr = values[field] || [];

    if (action === 'add') {
      setFieldValue(field, [...arr, value]);
    }

    if (action === 'remove') {
      setFieldValue(
        field,
        arr.filter((item) => item !== value)
      );
    }
  };

  const getValueArr = (field, value, { values }) => {
    const arr = values[field] || [];

    return arr.includes(value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      minHeight="calc(100vh - 251px)"
      maxWidth={800}
      sx={{
        margin: '0 auto',
      }}
    >
      <Typography variant="h5" fontWeight={600}>
        Configurações
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          tax_type: dataSettings?.tax_type?.split(',') || ['client'],
          tax_type_credit: dataSettings?.tax_type_credit?.split(',') || [
            'client',
          ],
          bank_account: {
            type: dataSettings?.bank_account?.type || '',
            document: dataSettings?.bank_account?.document || '',
            bank: dataSettings?.bank_account?.bank || '',
            agency: dataSettings?.bank_account?.agency || '',
            number: dataSettings?.bank_account?.number || '',
            number_digit: dataSettings?.bank_account?.number_digit || '',
            pix_key: dataSettings?.bank_account?.pix_key || '',
          },
        }}
        onSubmit={async (values) => {
          const newValues = { ...values };

          newValues.tax_type = newValues.tax_type.join(',');
          newValues.tax_type_credit = newValues.tax_type_credit.join(',');

          if (values?.bank_account?.bank?.Banco) {
            newValues.bank_account.bank = values.bank_account.bank.Banco;
          }

          const bankAccountFormatted = pickBy(newValues?.bank_account);

          if (bankAccountFormatted?.bank) {
            bankAccountFormatted.bank = String(bankAccountFormatted.bank);
          }

          if (bankAccountFormatted?.agency) {
            bankAccountFormatted.agency = String(bankAccountFormatted.agency);
          }

          if (bankAccountFormatted?.type === 'pix') {
            delete bankAccountFormatted.agency;
            delete bankAccountFormatted.bank;
            delete bankAccountFormatted.document;
            delete bankAccountFormatted.number;
            delete bankAccountFormatted.number_digit;
          }

          const [success] = await patchApiSettingsService({
            ...values,
            bank_account: bankAccountFormatted,
          } as any);

          if (success) {
            dispatch(settings.action.serviceGet());
          }

          return success;
        }}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap={3}>
              {isAdmin && (
                <>
                  <Box
                    display="flex"
                    gap={1.5}
                    flexDirection="column"
                    width="100%"
                  >
                    <Box display="flex" gap={1.5}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="trasso_heading_small">
                          Tipo de taxa (Débito)
                        </Typography>

                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(_, checked) => {
                                  setValeArr(
                                    'tax_type',
                                    'client',
                                    !checked ? 'remove' : 'add',
                                    {
                                      setFieldValue,
                                      values,
                                    }
                                  );
                                }}
                                checked={getValueArr('tax_type', 'client', {
                                  values,
                                })}
                              />
                            }
                            label="Cliente"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={getValueArr('tax_type', 'ec', {
                                  values,
                                })}
                                onChange={(_, checked) => {
                                  setValeArr(
                                    'tax_type',
                                    'ec',
                                    !checked ? 'remove' : 'add',
                                    {
                                      setFieldValue,
                                      values,
                                    }
                                  );
                                }}
                              />
                            }
                            label="Cartório"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                    <Box display="flex" gap={1.5}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="trasso_heading_small">
                          Tipo de taxa (Crédito)
                        </Typography>

                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(_, checked) => {
                                  setValeArr(
                                    'tax_type_credit',
                                    'client',
                                    !checked ? 'remove' : 'add',
                                    {
                                      setFieldValue,
                                      values,
                                    }
                                  );
                                }}
                                checked={getValueArr(
                                  'tax_type_credit',
                                  'client',
                                  {
                                    values,
                                  }
                                )}
                              />
                            }
                            label="Cliente"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={getValueArr('tax_type_credit', 'ec', {
                                  values,
                                })}
                                onChange={(_, checked) => {
                                  setValeArr(
                                    'tax_type_credit',
                                    'ec',
                                    !checked ? 'remove' : 'add',
                                    {
                                      setFieldValue,
                                      values,
                                    }
                                  );
                                }}
                              />
                            }
                            label="Cartório"
                          />
                        </FormGroup>
                      </Box>
                    </Box>

                    <Divider />

                    <Box display="flex" gap={1.5}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="trasso_heading_small">
                          Tipo de conta
                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={values?.bank_account?.type}
                          name="radio-buttons-group"
                          onChange={(ev, value) => {
                            setFieldValue('bank_account.type', value);
                          }}
                        >
                          <FormControlLabel
                            value="account"
                            checked={values.bank_account.type === 'account'}
                            control={
                              <Radio
                                sx={{
                                  '&.Mui-checked': {
                                    color: 'secondary.main',
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                variant="trasso_body_medium"
                                color="grey.900"
                              >
                                Conta bancária
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="pix"
                            checked={values.bank_account.type === 'pix'}
                            control={
                              <Radio
                                sx={{
                                  '&.Mui-checked': {
                                    color: 'secondary.main',
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                variant="trasso_body_medium"
                                color="trasso.purple.100"
                              >
                                PIX
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Box>
                    </Box>

                    {values.bank_account.type === 'pix' && (
                      <FieldLabelFormikComponent
                        size="small"
                        name="bank_account.pix_key"
                        label="Chave PIX"
                        fullWidth
                      />
                    )}

                    {values.bank_account.type === 'account' && (
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap={1.5}
                      >
                        <FieldDocumentComponent
                          size="small"
                          name="bank_account.document"
                          customInput={TextFieldLabelComponent}
                        />

                        <Autocomplete
                          noOptionsText="Nada encontrado"
                          getOptionLabel={(option: any) => option.BancoNome}
                          options={banksConstant}
                          defaultValue={bankObj(values.bank_account.bank)}
                          onChange={(e, value: any, reason, details) => {
                            setFieldValue('bank_account.bank', value);
                          }}
                          renderInput={(params) => (
                            <TextFieldLabelComponent
                              {...params}
                              label="Banco"
                              fullWidth
                              size="small"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>{params.InputProps.endAdornment}</>
                                ),
                              }}
                            />
                          )}
                        />
                        <FieldLabelFormikComponent
                          size="small"
                          name="bank_account.agency"
                          label="Agência"
                        />
                        <Box
                          display="grid"
                          gridTemplateColumns={'1fr 150px'}
                          gap={1.5}
                        >
                          <FieldLabelFormikComponent
                            size="small"
                            name="bank_account.number"
                            label="Número da conta"
                          />
                          <FieldLabelFormikComponent
                            size="small"
                            name="bank_account.number_digit"
                            label="Dígito da conta"
                          />
                        </Box>
                      </Box>
                    )}

                    <Box>
                      <LoadingButton
                        variant="contained"
                        size="small"
                        color="secondary"
                        loading={isSubmitting || loadingSettings}
                        type="submit"
                      >
                        Salvar
                      </LoadingButton>
                    </Box>
                  </Box>

                  <Divider />

                  <Box display="flex" flexDirection="column" gap={1.5}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" fontWeight={600}>
                        Geração de tokens
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={() => setModalCreate(true)}
                      >
                        Gerar
                      </Button>
                    </Box>

                    <Box
                      display="grid"
                      gridTemplateColumns="1fr 1fr"
                      gap={1.5}
                      position="relative"
                    >
                      {dataUserkeys?.map((userkey) => (
                        <Box
                          p={1.5}
                          bgcolor="grey.100"
                          borderRadius={2}
                          key={userkey?.id}
                          border="1px solid transparent"
                          borderColor="grey.300"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box>
                            <Typography variant="subtitle1">
                              <strong>Nome:</strong> {userkey?.name}
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={async () => {
                                const confirm = window.confirm(
                                  'Deseja excluir essa chave?'
                                );

                                if (!confirm) {
                                  return;
                                }

                                const [success] =
                                  await handleFetchDeleteUserkeys(userkey?.id);

                                if (success) {
                                  handleFetchUserkeys();
                                }
                              }}
                            >
                              Excluir
                            </Button>
                          </Box>
                        </Box>
                      ))}

                      {(loadingUserkeys || loadingDeleteUserkeys) && (
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          width="100%"
                          height="100%"
                          bgcolor="#ffffffa1"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress />
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Divider />
                </>
              )}

              <Box display="flex" gap={1.5} flexDirection="column">
                <Typography variant="subtitle1" fontWeight={600}>
                  Link do instalador
                </Typography>

                <Link
                  href="https://sipe-infra.s3.sa-east-1.amazonaws.com/Instalador.SIPE.exe"
                  target="_blank"
                  fontWeight={600}
                  color="primary.dark"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                  }}
                >
                  Instalador - PDV Web <OpenInNewIcon fontSize="small" />
                </Link>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {dialogDetails}
    </Box>
  );
};

export default ConfigurationScreen;
