import mock from '@giro-pdv-whitelabel/mocks/state.mock.json';

const initialState = {
  loading: null,
  data: null,
  error: [null, null],
  current: null,
};

// export default mock.pdv.transactions;
export default initialState;
