import React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Box, Link, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';

import pinpad from '@giro-pdv-whitelabel/modules/pdv/store/pinpad';

const SetupScreen = () => {
  const dispatch = useDispatch();

  const pinpadState = useSelector(pinpad.selector.selectState);

  const [hasErrorPinpad] = pinpadState?.error;

  return (
    <>
      <GlobalStyles
        styles={`
          #header-app, #footer-app {
            display: none;
          }

          body {
            background-color: #F4F0F7;
          }
        `}
      />

      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        width={{
          xs: '100%',
          md: 800,
        }}
        sx={{
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            margin: '0 auto',
            mb: 10,
          }}
        >
          <img src="/logorc.png" style={{ display: 'block' }} />
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="trasso_heading_medium" color="black">
            Você está a um passo para configurar seu PDV.
          </Typography>

          <Typography variant="body1" p={1} color="grey.1000">
            Desbloqueie todo o potencial do seu negócio! Descubra como o PDV da
            SIPE pode impulsionar o seu sucesso. Explore as vantagens e
            prepare-se para uma excelente experiência no seu ponto de venda!
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="trasso_heading_medium" color="black">
            1. Baixe o executável
          </Typography>

          <Typography variant="body1" p={1} color="grey.1000">
            Baixe agora o arquivo executável (exe) para desfrutar de todas as
            funcionalidades exclusivas.
          </Typography>

          <Link
            href="https://sipe-infra.s3.sa-east-1.amazonaws.com/Instalador.SIPE.exe"
            target="_blank"
            color="primary.dark"
            fontWeight="bold"
            textAlign="center"
          >
            Baixar instalador
          </Link>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="trasso_heading_medium" color="black">
            2. Faça a instalação
          </Typography>

          <Typography variant="body1" p={1} color="grey.1000">
            Descomplicamos para você! É tão fácil quanto clicar em{' '}
            {`'próximo,
            próximo...'`}{' '}
            Siga o passo a passo nas imagens e desfrute de uma experiência
            simples e intuitiva. Estamos aqui para tornar tudo mais fácil para
            você!
          </Typography>

          <Box display="flex" flexWrap={'wrap'} gap={2}>
            {Array(5)
              .fill(null)
              .map((_, idx) => (
                <img
                  key={idx}
                  src={`https://sipe-infra.s3.sa-east-1.amazonaws.com/steps/${
                    idx + 1
                  }.png`}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    width: 392,
                    maxWidth: '100%',
                  }}
                />
              ))}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="trasso_heading_medium" color="black">
            3. Tudo pronto!
          </Typography>

          <Typography variant="body1" p={1} color="grey.1000">
            Parabéns! Agora, basta verificar se a instalação está tudo certo e
            você está pronto para começar a usar. Se surgir qualquer dúvida,
            estamos aqui para ajudar. Boa utilização!
          </Typography>

          <Box textAlign="center">
            <LoadingButton
              variant="contained"
              onClick={() => {
                dispatch(pinpad.action.resetState());

                dispatch(pinpad.action.servicePostIsPresent());
              }}
              loading={pinpadState?.loading}
            >
              <Typography variant="body1" p={1} color="black">
                Testar PinPad
              </Typography>
            </LoadingButton>
          </Box>

          <Box textAlign="center">
            {!hasErrorPinpad && pinpadState?.isPresent && (
              <Typography>Pinpad conectado com sucesso!</Typography>
            )}

            {(hasErrorPinpad || pinpadState?.isPresent === false) && (
              <Typography>Erro ao conectar com o pinpad</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SetupScreen;
