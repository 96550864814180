import { Typography, Box, Button, Select, GlobalStyles } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import format from 'date-fns/format';

import getApiReceiptsIdService from '@giro/shared-services/pdv/getApiReceiptsId.service';

const ReceiptThermalScreen = () => {
  const params: any = useParams();

  const [via, setVia] = React.useState('Via estabelecimento');

  const [data, setData] = React.useState<any>({});

  React.useEffect(() => {
    getApiReceiptsIdService(params?.id).then((res) => {
      setData(res[1]);
    });
  }, []);

  return (
    <>
      <GlobalStyles
        styles={`
  * {
    font-size: 12px;
    font-family: 'Times New Roman';
}

#header {
  margin-bottom: 36px;
}

#containerScroll {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiContainer-root {
  max-width: none !important;
  padding: 12px !important;
}

#header-app, #footer-app {
  display: none;
}

td.item {
  width: 240px;
  font-weight: bold;
}

td.description,
th.description {
    width: 75px;
    max-width: 75px;
}

td.quantity,
th.quantity {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.price,
th.price {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

.centered {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 240px;
    max-width: 240px;
}

img {
    max-width: inherit;
    width: inherit;
}

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}
  `}
      />

      <Box className="hidden-print" display="flex" gap={2}>
        <Select
          size="small"
          defaultValue={via}
          native={true}
          onChange={(ev) => {
            setVia(ev.target.value);
          }}
          sx={{
            fontSize: 14,
          }}
        >
          <option value="Via estabelecimento">Via estabelecimento</option>
          <option value="Via cliente">Via cliente</option>
        </Select>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            window.print();
          }}
        >
          Imprimir
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" gap={0.5} width={240}>
        <div className="ticket">
          <p className="centered" style={{ fontWeight: 'bold' }}>
            <br />
            Registro Civil do Brasil
            <br />
            COMPROVANTE
          </p>
          <table>
            <tbody>
              <tr>
                <td className="item">
                  {data?.bandeira} {data?.binCartao}
                </td>
              </tr>
              <tr>
                <td className="item" style={{ textTransform: 'uppercase' }}>
                  {via}
                </td>
              </tr>
              <tr>
                <td className="item">
                  {data?.nuCPFCNPJ} {data?.softDescriptor}
                </td>
              </tr>
              <tr>
                <td className="item">
                  {data?.cidade}{' '}
                  {format(
                    new Date(data?.dtTransacao || Date.now()),
                    'dd/MM/yyyy HH:mm'
                  )}
                </td>
              </tr>
              <tr>
                <td className="item">
                  {String(data?.tipoPagamento)
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')}{' '}
                  {data?.parcelas}x
                </td>
              </tr>
              <tr>
                <td className="item">
                  Valor Servico:{' '}
                  {Number(data?.vlLiberado).toLocaleString('pt-br', {
                    currency: 'BRL',
                    style: 'currency',
                  })}
                </td>
              </tr>
              <tr>
                <td className="item">
                  Valor despesa financeira:{' '}
                  {Number(data?.vlDespesa).toLocaleString('pt-br', {
                    currency: 'BRL',
                    style: 'currency',
                  })}
                </td>
              </tr>
              <tr>
                <td className="item">
                  Valor total:{' '}
                  {Number(data?.vlTransacao).toLocaleString('pt-br', {
                    currency: 'BRL',
                    style: 'currency',
                  })}
                </td>
              </tr>
              <tr>
                <td className="item">Autorizacao: {data?.idTransacao}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    </>
  );
};

export default ReceiptThermalScreen;
