import fetch from '@giro/shared-utils/fetch.util';

export type BodyType = {
  document: string;
  model: 'gertec';
  quantity: number;
  address: {
    postalCode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
  };
};

export default async function postApiClientsOnboardingsService(body: BodyType) {
  return fetch('clients/onboardings', {
    method: 'POST',
    ms: 'PDV',
    auth: true,
    body: JSON.stringify(body),
  });
}
