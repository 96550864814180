import fetch from '@giro/shared-utils/fetch.util';

export type BodyType = {
  tax_type?: string;
  pix_key?: string;
};

export default async function patchApiSettingsService(body: BodyType) {
  return fetch(`settings`, {
    method: 'PATCH',
    ms: 'PDV',
    auth: true,
    body: JSON.stringify(body),
  });
}
