import { all } from 'redux-saga/effects';

import auth from '@giro/shared-store/auth';

import router from '@giro/shared-store/router';

import pdv from '@giro-pdv-whitelabel/modules/pdv/store';
import login from '@giro-pdv-whitelabel/modules/login/store';
import reports from '@giro-pdv-whitelabel/modules/reports/store';
import receipt from '@giro-pdv-whitelabel/modules/receipt/store';
import cb from '@giro-pdv-whitelabel/modules/cb/store';
import onboardingSaga from '@giro-pdv-whitelabel/modules/onboarding/store';
import configuration from '@giro-pdv-whitelabel/modules/configuration/store';

import dialogReceipt from './dialogReceipt';
import menu from './menu';

function* rootSaga() {
  yield all([
    cb.saga.watch(),
    onboardingSaga.saga.watch(),
    router.saga.watch(),
    pdv.saga.watch(),
    receipt.saga.watch(),
    reports.saga.watch(),
    login.saga.watch(),
    auth.saga.watch(),
    configuration.saga.watch(),
    dialogReceipt.saga.watch(),
    menu.saga.watch(),
  ]);
}

export default rootSaga;
