import mock from '@giro-pdv-whitelabel/mocks/state.mock.json';

const initialState = {
  loading: null,
  data: null,
  session: null,
  message: null,
  error: [null, null],
  tef: {},
  status: null,
  present: null,
  transaction_status: null,
};

// export default mock.pdv.pinpad;
export default initialState;
