import React, { useEffect } from 'react';
import { flow } from 'lodash';
import { Switch, Route, Redirect } from 'react-router-dom';
import LogRocket from 'logrocket';
import Helmet from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useNotification from '@giro/shared-hooks/useNotification.hook';
import useIsCreaHook from '@giro-pdv-whitelabel/hooks/useIsCrea.hook';
import useIsMobile from './hooks/useIsMobile.hook';

import PDVModule from '@giro-pdv-whitelabel/modules/pdv/PDV.module';
import ReportsModule from '@giro-pdv-whitelabel/modules/reports/Reports.module';
import LoginModule from '@giro-pdv-whitelabel/modules/login/Login.module';
import ReceiptModule from './modules/receipt/Receipt.module';
import ConfigurationModule from './modules/configuration/Configuration.module';
import CbModule from './modules/cb/Cb.module';
import OnboardingModule from './modules/onboarding/Onboarding.module';
import SetupModule from './modules/setup/Setup.module';
import DocumentationModule from './modules/documentation/Documentation.module';
import ErroLoginScreen from './modules/login/screens/ErroLogin.screen';

import fetch from '@giro/shared-utils/fetch.util';

import LogoffScreen from './modules/login/screens/Logoff.screen';

import DialogReceiptComponent from './components/DialogReceipt.component';

import ROUTES from './constants/routes.constant';

import menu from './store/menu';
import onboardingClient from './modules/onboarding/store/onboardingClient';
import auth from '@giro/shared-store/auth';
import login from './modules/login/store/login';
import clients from './modules/pdv/store/clients';
import settings from './modules/configuration/store/settings';

const Routes = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { user, signedIn } = useAuth();
  const dispatch = useDispatch();

  const loginStore = useSelector(login.selector.selectState);
  const clientStore = useSelector(clients.selector.selectState);

  const installHeader = React.useRef(false);

  const dispatchRedux = {
    close: flow(menu.action.close, dispatch),
  };

  useNotification();

  const onboardingClientState = useSelector(
    onboardingClient.selector.selectState
  );

  React.useEffect(() => {
    if (signedIn) {
      dispatch(settings.action.serviceGet());
    }
  }, [signedIn]);

  React.useEffect(() => {
    if (clientStore.loading !== false || !signedIn) {
      return undefined;
    }

    const { cns } =
      JSON.parse(localStorage.getItem('cartorio') as string) || {};

    if (!cns) {
      return undefined;
    }

    const { user: userData } = user;
    const { cdCliente } = clientStore.payload || {};

    if (!cdCliente) {
      return undefined;
    }

    const { cns: cnsClient } =
      userData?.clients?.find((client) => client?.cdCliente == cdCliente) || {};

    if (cns !== cnsClient) {
      (async () => {
        const [success, result] = await fetch('auth/clients', {
          auth: true,
          ms: 'PDV',
          method: 'POST',
          body: JSON.stringify({ cns }),
        });

        if (success) {
          dispatch(auth.action.setToken(result.token));

          window.location.reload();
        }
      })();
    }
  }, [clientStore, signedIn]);

  React.useEffect(() => {
    if (!signedIn || pathname === ROUTES.LOGIN.ROOT) {
      return undefined;
    }

    const instalarHeaderFooter = (window as any).instalarHeaderFooter;

    if (instalarHeaderFooter && !installHeader.current) {
      const tokenIdrc = loginStore?.token_idrc;
      const tokenIdrcDecoded = tokenIdrc && jwtDecode(tokenIdrc);

      const cpfFormatted = tokenIdrcDecoded?.preferred_username?.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );

      instalarHeaderFooter({
        usuario: {
          nome: user?.user?.name,
          cpf: cpfFormatted,
        },
        urlLogin: process.env.REACT_APP_CB_LOGIN_URL,
        urlLogout: ROUTES.LOGIN.LOGOFF,
        token: loginStore?.token_idrc,
      });

      installHeader.current = true;
    }
  });

  useEffect(() => {
    if (!signedIn) return undefined;

    setTimeout(() => {
      const buttonChangeCart = document.querySelector(
        '#botaoSelecionarCartorio'
      );

      if (buttonChangeCart) {
        buttonChangeCart.addEventListener('click', () => {
          setTimeout(async () => {
            const { cns } = JSON.parse(
              localStorage.getItem('cartorio') as string
            );

            const [success, result] = await fetch('auth/clients', {
              auth: true,
              ms: 'PDV',
              method: 'POST',
              body: JSON.stringify({ cns }),
            });

            if (success) {
              dispatch(auth.action.setToken(result.token));

              window.location.reload();
            }
          });
        });
      }
    }, 1000);
  }, [signedIn]);

  useEffect(() => {
    if (signedIn) {
      dispatch(onboardingClient.action.serviceGet());
    }
  }, [signedIn]);

  useEffect(() => {
    if (
      onboardingClientState.loading !== false ||
      pathname === ROUTES.LOGIN.LOGOFF
    ) {
      return undefined;
    }

    const { valid } = onboardingClientState.data || {};
    const isAdmin = user?.user?.codes?.idRole === 4;

    if (!valid && isAdmin && ROUTES.ONBOARDING.ROOT !== pathname) {
      window.location.href = ROUTES.ONBOARDING.ROOT;
    }

    if (!valid && !isAdmin) {
      dispatch(
        auth.action.unsetToken(
          'Ativação permitida somente pelo Oficial da Serventia.'
        )
      );
    }
  }, [onboardingClientState.loading, pathname]);

  useEffect(() => {
    if (isMobile) {
      dispatchRedux.close();
    }
  }, [pathname, isMobile]);

  useEffect(() => {
    if (process.env.REACT_APP_LOGROCKET === 'true') {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID as string);
    }
  }, []);

  useEffect(() => {
    if (signedIn && process.env.REACT_APP_LOGROCKET === 'true') {
      const { user: userState } = user || {};

      LogRocket.identify(userState.id, {
        name: userState.name,
        email: userState.email,
      });
    }
  }, [signedIn]);

  const isCrea = useIsCreaHook();

  const ROOTROUTER = isCrea ? ROUTES.REPORTS.ROOT : ROUTES.PDV.ROOT;

  return (
    <>
      <Switch>
        {!isCrea && <Route path={ROUTES.PDV.ROOT} component={PDVModule} />}
        <Route path={ROUTES.REPORTS.ROOT} component={ReportsModule} />
        <Route exact path={ROUTES.LOGIN.LOGOFF} component={LogoffScreen} />
        <Route path={ROUTES.LOGIN.ROOT} component={LoginModule} />
        <Route path={ROUTES.RECEIPT.ROOT} component={ReceiptModule} />
        <Route path={ROUTES.CONFIGURATIONS} component={ConfigurationModule} />
        <Route path={ROUTES.CB.ROOT} component={CbModule} />
        <Route path={ROUTES.ONBOARDING.ROOT} component={OnboardingModule} />
        <Route path={ROUTES.SETUP.ROOT} component={SetupModule} />
        <Route path={ROUTES.DOCUMENTATION} component={DocumentationModule} />
        <Route exact path={ROUTES.LOGIN_ERROR} component={ErroLoginScreen} />

        <Redirect to={ROOTROUTER} />
      </Switch>

      <DialogReceiptComponent />
    </>
  );
};

export default Routes;
