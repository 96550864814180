import React from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import tablePayment from '../store/tablePayment';

const UpdateAmountComponent = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    updateAmount: flow(tablePayment.action.updateAmount, dispatch),
    updateInstallmentSelected: flow(
      tablePayment.action.updateInstallmentSelected,
      dispatch
    ),
  };

  const installmentSelected = useSelector(
    tablePayment.selector.selectInstallmentSelected
  );

  const returnVal = useSelector(tablePayment.selector.selectReturn);

  const { values }: any = useFormikContext();

  React.useEffect(() => {
    dispatchRedux.updateAmount(Number(values.total) || 0);
  }, [values.total]);

  React.useEffect(() => {
    if (installmentSelected !== null) {
      dispatchRedux.updateInstallmentSelected(null);
    }
  }, [values.type_tax, values.type_tax_credit, returnVal, values.total]);

  return <></>;
};

export default UpdateAmountComponent;
