const NAMESPACE = '/';
const NAMESPACE_PDV = '/pdv';
const NAMESPACE_REPORTS = '/reports';
const NAMESPACE_LOGIN = '/login';
const NAMESPACE_RECEIPT = '/receipt';
const NAMESPACE_CONFIGURATIONS = '/configuration';
const NAMESPACE_CB = '/cb';
const NAMESPACE_ONBOARDING = '/onboarding';
const NAMESPACE_SETUP = '/setup';
const NAMESPACE_DOCUMENTATION = '/documentation';

const PDV_ROUTES = {
  ROOT: NAMESPACE_PDV,
  AGENT: `${NAMESPACE_PDV}/agent`,
  VEHICLE: `${NAMESPACE_PDV}/vehicle`,
  GERAL: `${NAMESPACE_PDV}/geral`,
  CEMITERIO: `${NAMESPACE_PDV}/cemiterio`,
  EDUCACAO: `${NAMESPACE_PDV}/educacao`,
  ASSESSORIA: `${NAMESPACE_PDV}/assessoria`,
  DETRAN: `${NAMESPACE_PDV}/detran`,
  BILLET: `${NAMESPACE_PDV}/billet`,
  TOTEM: `${NAMESPACE_PDV}/totem`,
  FINANCING: `${NAMESPACE_PDV}/financing`,
  MOBILE: `${NAMESPACE_PDV}/mobile`,
  RECHARGE: `${NAMESPACE_PDV}/recharge-mobile`,
  SIMULATION: `${NAMESPACE_PDV}/generate-simulation`,
  CARTORIO: `${NAMESPACE_PDV}/cartorio`,
  NEW_CARTORIO: `${NAMESPACE_PDV}/novo-cartorio`,
  ABOUT: `${NAMESPACE_PDV}/about`,
};

const RECEIPT_ROUTES = {
  ROOT: NAMESPACE_RECEIPT,
  ID: (id) => `${NAMESPACE_RECEIPT}/${id}`,
};

const SETUP_ROUTES = {
  ROOT: NAMESPACE_SETUP,
};

const CB_ROUTES = {
  ROOT: NAMESPACE_CB,
  ID: (id = '') => (id ? `${NAMESPACE_CB}/${id}` : `${NAMESPACE_CB}/:id`),
};

const REPORTS_ROUTES = {
  ROOT: NAMESPACE_REPORTS,
  EC: `${NAMESPACE_REPORTS}/ec`,
  NEW_EC: `${NAMESPACE_REPORTS}/novo-ec`,
  FINANCING: `${NAMESPACE_REPORTS}/financing`,
  CREA: `${NAMESPACE_REPORTS}/crea`,
};

const LOGIN_ROUTES = {
  ROOT: NAMESPACE_LOGIN,
  CHANGE_PASSWORD: `${NAMESPACE_LOGIN}/change-password`,
  LOGOFF: `${NAMESPACE_LOGIN}/logoff`,
};

const ONBOARDING_ROUTES = {
  ROOT: NAMESPACE_ONBOARDING,
};

const EXTERNAL_PINPAD_ROUTE = 'https://127.0.0.1/agente/clisitef';

const EXTERNAL_ROUTES = {
  PINPAD: EXTERNAL_PINPAD_ROUTE,
};

export const NAMESPACES = {
  ROOT: NAMESPACE,
  PDV: NAMESPACE_PDV,
  REPORTS: NAMESPACE_REPORTS,
  LOGIN: NAMESPACE_LOGIN,
  RECEIPT: NAMESPACE_RECEIPT,
  CONFIGURATIONS: NAMESPACE_CONFIGURATIONS,
  CB: NAMESPACE_CB,
  ONBOARDING: NAMESPACE_ONBOARDING,
  SETUP: NAMESPACE_SETUP,
  DOCUMENTATION: NAMESPACE_DOCUMENTATION,
};

const ROUTES = {
  HOME: NAMESPACE,
  LOGIN_ERROR: `${NAMESPACE}erro-login`,
  PDV: PDV_ROUTES,
  LOGIN: LOGIN_ROUTES,
  REPORTS: REPORTS_ROUTES,
  EXTERNAL: EXTERNAL_ROUTES,
  RECEIPT: RECEIPT_ROUTES,
  CONFIGURATIONS: NAMESPACE_CONFIGURATIONS,
  CB: CB_ROUTES,
  ONBOARDING: ONBOARDING_ROUTES,
  SETUP: SETUP_ROUTES,
  DOCUMENTATION: NAMESPACE_DOCUMENTATION,
};

export default ROUTES;
