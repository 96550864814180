import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

const DialogQuantityComponent = ({ handleClose, visible }) => {
  return (
    <Dialog open={visible} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="trasso_body_medium">
            Quantidade de maquininhas
          </Typography>
        </Box>
      </DialogTitle>
      <Box
        minWidth={400}
        display="flex"
        flexDirection="column"
        gap={2}
        px={3}
        pb={3}
        position="relative"
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="trasso_body_small" color="grey.600">
            Interessado em um número maior de maquininhas? Estamos abertos a
            negociações personalizadas! Entre em contato conosco para discutir
            as melhores opções para as suas necessidades.
          </Typography>
        </Box>

        <Button variant="outlined" onClick={handleClose}>
          Fechar
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogQuantityComponent;
