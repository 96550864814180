import React from 'react';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { flow } from 'lodash';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';

import ButtonPayActionsComponent from '../components/ButtonPayActions.component';
import FieldCurrencyComponent, {
  FieldCurrencyComponentDigits,
} from '@giro/shared-components/Fields/FieldCurrency.component';
import FieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';

import TextFieldLabelComponent from '@giro-pdv-whitelabel/components/TextFieldLabel.component';

import PDVLayout from '../layouts/PDV.layout';

import UpdateAmountComponent from '../components/UpdateAmount.component';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';
import linkedkey from '@giro-pdv-whitelabel/modules/cb/store/linkedkey';
import pinpad from '../store/pinpad';
import settings from '@giro-pdv-whitelabel/modules/configuration/store/settings';

const normalizeType = {
  credito: 'Crédito',
  debito: 'Débito',
  pix: 'PIX',
};

const FormGeral = () => {
  const settingsState = useSelector(settings.selector.selectState);

  const dispatch = useDispatch();
  const { user } = useAuthHook();
  const formikRef: any = React.useRef(null);
  const [dom, setDom] = React.useState(false);
  const [linkedLoading, setLinkedLoading] = React.useState(false);

  const linkedKeyState = useSelector(linkedkey.selector.selectState);
  const tablePaymensState = useSelector(tablePayment.selector.selectState);

  const dispatchRedux = {
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
    updateTypeTaxCredit: flow(
      tablePayment.action.updateTypeTaxCredit,
      dispatch
    ),
  };

  const selectorRedux = {
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
    state: useSelector(pinpad.selector.selectState),
  };

  const creditTax = settingsState?.data?.tax_type_credit?.split(',');
  const debitTax = settingsState?.data?.tax_type?.split(',');

  React.useEffect(() => {
    if (!creditTax || !debitTax) {
      return undefined;
    }

    dispatchRedux.updateTypeTaxCredit(creditTax[0]);
    dispatchRedux.updateTypeTax(debitTax[0]);
    formikRef.current.setFieldValue('type_tax_credit', creditTax[0]);
    formikRef.current.setFieldValue('type_tax', debitTax[0]);
  }, [settingsState.data]);

  React.useEffect(() => {
    if (selectorRedux.state.transaction_status === 'transaction_approved') {
      formikRef.current.setFieldValue('total', 0);
    }
    return undefined;
  }, [selectorRedux.state.transaction_status]);

  React.useEffect(() => {
    setDom(true);
  }, []);

  React.useEffect(() => {
    if (!dom) {
      return undefined;
    }

    const [hasError] = linkedKeyState?.error || [];

    if (hasError) {
      return undefined;
    }

    const { valor } = linkedKeyState?.data || {};

    formikRef.current.setFieldValue('total', valor);

    setLinkedLoading(true);
  }, [dom]);

  React.useEffect(() => {
    if (linkedLoading === false) {
      return undefined;
    }

    const { pagamento, parcelas } = linkedKeyState?.data || {};
    const { rows } = tablePaymensState || {};

    const parcelaFormated = pagamento === 'credito' ? parcelas : 'Única';

    const onlyInstallmentsByType = rows?.filter(
      (item: any) => item.type === normalizeType[pagamento]
    );

    const installmentSelected = (onlyInstallmentsByType || []).find(
      (item: any) => item.installment === parcelaFormated
    );

    if (installmentSelected) {
      dispatch(
        tablePayment.action.updateInstallmentSelected(installmentSelected)
      );

      dispatch(linkedkey.action.resetState());

      setTimeout(() => {
        (document as any).querySelector('#btn-pay-pinpad').click();
      }, 200);
    }
  }, [tablePaymensState.rows, linkedLoading]);

  const initialValues = {
    client: null,
    service: null,
    total: null,
    segment: '16',
    dsIdentificacaoServico: null,
    dsIdentificacaoCliente: null,
    att_password: null,
    type_tax: 'client',
    type_tax_credit: 'client',
  };

  const onSubmit = () => {};

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, setFieldValue }) => {
        return (
          <>
            <UpdateAmountComponent />
            <Box
              display="flex"
              flexDirection="column"
              gap={4}
              sx={{
                minHeight: 'calc(100vh - 252px)',
              }}
            >
              <Box display="flex" flexDirection="column" gap={3}>
                <FieldLabelFormikComponent
                  size="small"
                  name="dsIdentificacaoServico"
                  label="Identificação do Serviço"
                />
                <FieldLabelFormikComponent
                  size="small"
                  name="dsIdentificacaoCliente"
                  label="Identificação do Cliente"
                />
                <FieldCurrencyComponentDigits
                  customInput={TextFieldLabelComponent}
                  size="small"
                  name="total"
                  label="Serviços"
                />
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="trasso_heading_small">
                      Tipo de taxa (Débito)
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="portador"
                      name="radio-buttons-group"
                      onChange={(ev, value) => {
                        setFieldValue('type_tax', value);

                        dispatchRedux.updateTypeTax(value);

                        if (value === 'ec') {
                          dispatchRedux.updateReturn(0);
                        }
                      }}
                    >
                      {debitTax?.includes('client') && (
                        <FormControlLabel
                          value="client"
                          checked={values.type_tax === 'client'}
                          control={<Radio color="primary" />}
                          id="radio-client"
                          label={
                            <Typography
                              variant="trasso_body_medium"
                              color="grey.900"
                            >
                              Cliente
                            </Typography>
                          }
                        />
                      )}
                      {debitTax?.includes('ec') && (
                        <FormControlLabel
                          value="ec"
                          id="radio-ec"
                          checked={values.type_tax === 'ec'}
                          control={<Radio color="primary" />}
                          label={
                            <Typography
                              variant="trasso_body_medium"
                              color="trasso.purple.100"
                            >
                              Cartório
                            </Typography>
                          }
                        />
                      )}
                    </RadioGroup>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="trasso_heading_small">
                      Tipo de taxa (Crédito)
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="portador"
                      name="radio-buttons-group"
                      onChange={(ev, value) => {
                        setFieldValue('type_tax_credit', value);

                        dispatchRedux.updateTypeTaxCredit(value);

                        if (value === 'ec') {
                          dispatchRedux.updateReturn(0);
                        }
                      }}
                    >
                      {creditTax?.includes('client') && (
                        <FormControlLabel
                          value="client"
                          checked={values.type_tax_credit === 'client'}
                          control={<Radio color="primary" />}
                          id="radio-client"
                          label={
                            <Typography
                              variant="trasso_body_medium"
                              color="grey.900"
                            >
                              Cliente
                            </Typography>
                          }
                        />
                      )}
                      {creditTax?.includes('ec') && (
                        <FormControlLabel
                          value="ec"
                          id="radio-ec"
                          checked={values.type_tax_credit === 'ec'}
                          control={<Radio color="primary" />}
                          label={
                            <Typography
                              variant="trasso_body_medium"
                              color="trasso.purple.100"
                            >
                              Cartório
                            </Typography>
                          }
                        />
                      )}
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr" gap={4}>
                <ButtonPayActionsComponent
                  loading={selectorRedux.transactionsLoading}
                  onClickPay={() =>
                    dispatchRedux.transactionsServicePost({
                      ...values,
                    })
                  }
                  disabledPay={
                    selectorRedux.transactionsLoading ||
                    selectorRedux.installmentSelected === null ||
                    Number((values as any).total) === 0
                  }
                  disabledSend={Number((values as any).total) === 0}
                />
              </Box>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

const NewCartorioScreen = () => {
  return (
    <PDVLayout>
      <FormGeral />
    </PDVLayout>
  );
};

export default NewCartorioScreen;
