import React from 'react';
import { TextField, TextFieldProps, Box, Typography } from '@mui/material';
import { TextField as TextFieldFormik } from 'formik-mui';
import { Field } from 'formik';

const TextFieldLabelFormikComponent = (
  props: TextFieldProps & { name: string }
) => {
  const { label, name, ...restProps } = props;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {label && (
        <Typography
          variant="trasso_heading_small"
          fontSize="14px"
          color={restProps?.disabled ? '#717D96' : 'grey.900'}
        >
          {label}
        </Typography>
      )}
      <Field
        name={name}
        component={TextFieldFormik}
        variant="outlined"
        sx={{
          '& .MuiOutlinedInput-root': {
            color: 'trasso.purple.100',
            '& fieldset': {
              borderColor: 'trasso.gray.40',
            },
          },
        }}
        {...restProps}
      />
    </Box>
  );
};

export default TextFieldLabelFormikComponent;
