const banksContant = [
  {
    Banco: 1,
    BancoNome: 'BANCO DO BRASIL S.A (BB)',
    ISPB: '00000000',
  },
  {
    Banco: 237,
    BancoNome: 'BRADESCO S.A',
    ISPB: '60746948',
  },
  {
    Banco: 335,
    BancoNome: 'Banco Digio S.A',
    ISPB: '27098060',
  },
  {
    Banco: 260,
    BancoNome: 'NU PAGAMENTOS S.A (NUBANK)',
    ISPB: '18236120',
  },
  {
    Banco: 290,
    BancoNome: 'Pagseguro Internet S.A',
    ISPB: '08561701',
  },
  {
    Banco: 323,
    BancoNome: 'Mercado Pago – conta do Mercado Livre',
    ISPB: '10573521',
  },
  {
    Banco: 237,
    BancoNome: 'NEXT BANK (UTILIZAR O MESMO CÓDIGO DO BRADESCO)',
    ISPB: '60746948',
  },
  {
    Banco: 637,
    BancoNome: 'BANCO SOFISA S.A (SOFISA DIRETO)',
    ISPB: '60889128',
  },
  {
    Banco: 77,
    BancoNome: 'BANCO INTER S.A',
    ISPB: '00416968',
  },
  {
    Banco: 341,
    BancoNome: 'ITAÚ UNIBANCO S.A',
    ISPB: '60701190',
  },
  {
    Banco: 104,
    BancoNome: 'CAIXA ECONÔMICA FEDERAL (CEF)',
    ISPB: '00360305',
  },
  {
    Banco: 33,
    BancoNome: 'BANCO SANTANDER BRASIL S.A',
    ISPB: '90400888',
  },
  {
    Banco: 212,
    BancoNome: 'BANCO ORIGINAL S.A',
    ISPB: '92894922',
  },
  {
    Banco: 756,
    BancoNome: 'BANCOOB (BANCO COOPERATIVO DO BRASIL)',
    ISPB: '02038232',
  },
  {
    Banco: 655,
    BancoNome: 'BANCO VOTORANTIM S.A',
    ISPB: '59588111',
  },
  {
    Banco: 655,
    BancoNome: 'NEON PAGAMENTOS S.A (OS MESMOS DADOS DO BANCO VOTORANTIM)',
    ISPB: '59588111',
  },
  {
    Banco: 41,
    BancoNome: 'BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A',
    ISPB: '92702067',
  },
  {
    Banco: 389,
    BancoNome: 'BANCO MERCANTIL DO BRASIL S.A',
    ISPB: '17184037',
  },
  {
    Banco: 422,
    BancoNome: 'BANCO SAFRA S.A',
    ISPB: '58160789',
  },
  {
    Banco: 70,
    BancoNome: 'BANCO DE BRASÍLIA (BRB)',
    ISPB: '00000208',
  },
  {
    Banco: 136,
    BancoNome: 'UNICRED COOPERATIVA',
    ISPB: '00315557',
  },
  {
    Banco: 741,
    BancoNome: 'BANCO RIBEIRÃO PRETO',
    ISPB: '00517645',
  },
  {
    Banco: 739,
    BancoNome: 'BANCO CETELEM S.A',
    ISPB: '00558456',
  },
  {
    Banco: 743,
    BancoNome: 'BANCO SEMEAR S.A',
    ISPB: '00795423',
  },
  {
    Banco: 100,
    BancoNome: 'PLANNER CORRETORA DE VALORES S.A',
    ISPB: '00806535',
  },
  {
    Banco: 96,
    BancoNome: 'BANCO B3 S.A',
    ISPB: '00997185',
  },
  {
    Banco: 747,
    BancoNome: 'Banco RABOBANK INTERNACIONAL DO BRASIL S.A',
    ISPB: '01023570',
  },
  {
    Banco: 748,
    BancoNome: 'SICREDI S.A',
    ISPB: '01181521',
  },
  {
    Banco: 752,
    BancoNome: 'BNP PARIBAS BRASIL S.A',
    ISPB: '01522368',
  },
  {
    Banco: 91,
    BancoNome: 'UNICRED CENTRAL RS',
    ISPB: '01634601',
  },
  {
    Banco: 399,
    BancoNome: 'KIRTON BANK',
    ISPB: '01701201',
  },
  {
    Banco: 108,
    BancoNome: 'PORTOCRED S.A',
    ISPB: '01800019',
  },
  {
    Banco: 757,
    BancoNome: 'BANCO KEB HANA DO BRASIL S.A',
    ISPB: '02318507',
  },
  {
    Banco: 102,
    BancoNome: 'XP INVESTIMENTOS S.A',
    ISPB: '02332886',
  },
  {
    Banco: 348,
    BancoNome: 'BANCO XP S/A',
    ISPB: '33264668',
  },
  {
    Banco: 340,
    BancoNome: 'SUPER PAGAMENTOS S/A (SUPERDITAL)',
    ISPB: '09554480',
  },
  {
    Banco: 364,
    BancoNome: 'GERENCIANET PAGAMENTOS DO BRASIL',
    ISPB: '09089356',
  },
  {
    Banco: 84,
    BancoNome: 'UNIPRIME NORTE DO PARANÁ',
    ISPB: '02398976',
  },
  {
    Banco: 180,
    BancoNome: 'CM CAPITAL MARKETS CCTVM LTDA',
    ISPB: '02685483',
  },
  {
    Banco: 66,
    BancoNome: 'BANCO MORGAN STANLEY S.A',
    ISPB: '02801938',
  },
  {
    Banco: 15,
    BancoNome: 'UBS BRASIL CCTVM S.A',
    ISPB: '02819125',
  },
  {
    Banco: 143,
    BancoNome: 'TREVISO CC S.A',
    ISPB: '02992317',
  },
  {
    Banco: 62,
    BancoNome: 'HIPERCARD BM S.A',
    ISPB: '03012230',
  },
  {
    Banco: 74,
    BancoNome: 'BCO. J.SAFRA S.A',
    ISPB: '03017677',
  },
  {
    Banco: 99,
    BancoNome: 'UNIPRIME CENTRAL CCC LTDA',
    ISPB: '03046391',
  },
  {
    Banco: 25,
    BancoNome: 'BANCO ALFA S.A.',
    ISPB: '03323840',
  },
  {
    Banco: 75,
    BancoNome: 'BCO ABN AMRO S.A',
    ISPB: '03532415',
  },
  {
    Banco: 40,
    BancoNome: 'BANCO CARGILL S.A',
    ISPB: '03609817',
  },
  {
    Banco: 190,
    BancoNome: 'SERVICOOP',
    ISPB: '03973814',
  },
  {
    Banco: 63,
    BancoNome: 'BANCO BRADESCARD',
    ISPB: '04184779',
  },
  {
    Banco: 191,
    BancoNome: 'NOVA FUTURA CTVM LTDA',
    ISPB: '04257795',
  },
  {
    Banco: 64,
    BancoNome: 'GOLDMAN SACHS DO BRASIL BM S.A',
    ISPB: '04332281',
  },
  {
    Banco: 97,
    BancoNome: 'CCC NOROESTE BRASILEIRO LTDA',
    ISPB: '04632856',
  },
  {
    Banco: 16,
    BancoNome: 'CCM DESP TRÂNS SC E RS',
    ISPB: '04715685',
  },
  {
    Banco: 12,
    BancoNome: 'BANCO INBURSA',
    ISPB: '04866275',
  },
  {
    Banco: 3,
    BancoNome: 'BANCO DA AMAZONIA S.A',
    ISPB: '04902979',
  },
  {
    Banco: 60,
    BancoNome: 'CONFIDENCE CC S.A',
    ISPB: '04913129',
  },
  {
    Banco: 37,
    BancoNome: 'BANCO DO ESTADO DO PARÁ S.A',
    ISPB: '04913711',
  },
  {
    Banco: 159,
    BancoNome: 'CASA CREDITO S.A',
    ISPB: '05442029',
  },
  {
    Banco: 172,
    BancoNome: 'ALBATROSS CCV S.A',
    ISPB: '05452073',
  },
  {
    Banco: 85,
    BancoNome: 'COOP CENTRAL AILOS',
    ISPB: '05463212',
  },
  {
    Banco: 114,
    BancoNome: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO',
    ISPB: '05790149',
  },
  {
    Banco: 36,
    BancoNome: 'BANCO BBI S.A',
    ISPB: '06271464',
  },
  {
    Banco: 394,
    BancoNome: 'BANCO BRADESCO FINANCIAMENTOS S.A',
    ISPB: '07207996',
  },
  {
    Banco: 4,
    BancoNome: 'BANCO DO NORDESTE DO BRASIL S.A.',
    ISPB: '07237373',
  },
  {
    Banco: 320,
    BancoNome: 'BANCO CCB BRASIL S.A',
    ISPB: '07450604',
  },
  {
    Banco: 189,
    BancoNome: 'HS FINANCEIRA',
    ISPB: '07512441',
  },
  {
    Banco: 105,
    BancoNome: 'LECCA CFI S.A',
    ISPB: '07652226',
  },
  {
    Banco: 76,
    BancoNome: 'BANCO KDB BRASIL S.A.',
    ISPB: '07656500',
  },
  {
    Banco: 82,
    BancoNome: 'BANCO TOPÁZIO S.A',
    ISPB: '07679404',
  },
  {
    Banco: 286,
    BancoNome: 'CCR DE OURO',
    ISPB: '07853842',
  },
  {
    Banco: 93,
    BancoNome: 'PÓLOCRED SCMEPP LTDA',
    ISPB: '07945233',
  },
  {
    Banco: 273,
    BancoNome: 'CCR DE SÃO MIGUEL DO OESTE',
    ISPB: '08253539',
  },
  {
    Banco: 157,
    BancoNome: 'ICAP DO BRASIL CTVM LTDA',
    ISPB: '09105360',
  },
  {
    Banco: 183,
    BancoNome: 'SOCRED S.A',
    ISPB: '09210106',
  },
  {
    Banco: 14,
    BancoNome: 'NATIXIS BRASIL S.A',
    ISPB: '09274232',
  },
  {
    Banco: 130,
    BancoNome: 'CARUANA SCFI',
    ISPB: '09313766',
  },
  {
    Banco: 127,
    BancoNome: 'CODEPE CVC S.A',
    ISPB: '09512542',
  },
  {
    Banco: 79,
    BancoNome: 'BANCO ORIGINAL DO AGRONEGÓCIO S.A',
    ISPB: '09516419',
  },
  {
    Banco: 81,
    BancoNome: 'BBN BANCO BRASILEIRO DE NEGOCIOS S.A',
    ISPB: '10264663',
  },
  {
    Banco: 118,
    BancoNome: 'STANDARD CHARTERED BI S.A',
    ISPB: '11932017',
  },
  {
    Banco: 133,
    BancoNome: 'CRESOL CONFEDERAÇÃO',
    ISPB: '10398952',
  },
  {
    Banco: 121,
    BancoNome: 'BANCO AGIBANK S.A',
    ISPB: '10664513',
  },
  {
    Banco: 83,
    BancoNome: 'BANCO DA CHINA BRASIL S.A',
    ISPB: '10690848',
  },
  {
    Banco: 138,
    BancoNome: 'GET MONEY CC LTDA',
    ISPB: '10853017',
  },
  {
    Banco: 24,
    BancoNome: 'BCO BANDEPE S.A',
    ISPB: '10866788',
  },
  {
    Banco: 95,
    BancoNome: 'BANCO CONFIDENCE DE CÂMBIO S.A',
    ISPB: '11703662',
  },
  {
    Banco: 94,
    BancoNome: 'BANCO FINAXIS',
    ISPB: '11758741',
  },
  {
    Banco: 276,
    BancoNome: 'SENFF S.A',
    ISPB: '11970623',
  },
  {
    Banco: 137,
    BancoNome: 'MULTIMONEY CC LTDA',
    ISPB: '12586596',
  },
  {
    Banco: 92,
    BancoNome: 'BRK S.A',
    ISPB: '12865507',
  },
  {
    Banco: 47,
    BancoNome: 'BANCO BCO DO ESTADO DE SERGIPE S.A',
    ISPB: '13009717',
  },
  {
    Banco: 144,
    BancoNome: 'BEXS BANCO DE CAMBIO S.A.',
    ISPB: '13059145',
  },
  {
    Banco: 126,
    BancoNome: 'BR PARTNERS BI',
    ISPB: '13220493',
  },
  {
    Banco: 301,
    BancoNome: 'BPP INSTITUIÇÃO DE PAGAMENTOS S.A',
    ISPB: '13370835',
  },
  {
    Banco: 173,
    BancoNome: 'BRL TRUST DTVM SA',
    ISPB: '13486793',
  },
  {
    Banco: 119,
    BancoNome: 'BANCO WESTERN UNION',
    ISPB: '13720915',
  },
  {
    Banco: 254,
    BancoNome: 'PARANA BANCO S.A',
    ISPB: '14388334',
  },
  {
    Banco: 268,
    BancoNome: 'BARIGUI CH',
    ISPB: '14511781',
  },
  {
    Banco: 107,
    BancoNome: 'BANCO BOCOM BBM S.A',
    ISPB: '15114366',
  },
  {
    Banco: 412,
    BancoNome: 'BANCO CAPITAL S.A',
    ISPB: '15173776',
  },
  {
    Banco: 124,
    BancoNome: 'BANCO WOORI BANK DO BRASIL S.A',
    ISPB: '15357060',
  },
  {
    Banco: 149,
    BancoNome: 'FACTA S.A. CFI',
    ISPB: '15581638',
  },
  {
    Banco: 197,
    BancoNome: 'STONE PAGAMENTOS S.A',
    ISPB: '16501555',
  },
  {
    Banco: 142,
    BancoNome: 'BROKER BRASIL CC LTDA',
    ISPB: '16944141',
  },
  {
    Banco: 389,
    BancoNome: 'BANCO MERCANTIL DO BRASIL S.A.',
    ISPB: '17184037',
  },
  {
    Banco: 184,
    BancoNome: 'BANCO ITAÚ BBA S.A',
    ISPB: '17298092',
  },
  {
    Banco: 634,
    BancoNome: 'BANCO TRIANGULO S.A (BANCO TRIÂNGULO)',
    ISPB: '17351180',
  },
  {
    Banco: 545,
    BancoNome: 'SENSO CCVM S.A',
    ISPB: '17352220',
  },
  {
    Banco: 132,
    BancoNome: 'ICBC DO BRASIL BM S.A',
    ISPB: '17453575',
  },
  {
    Banco: 298,
    BancoNome: 'VIPS CC LTDA',
    ISPB: '17772370',
  },
  {
    Banco: 129,
    BancoNome: 'UBS BRASIL BI S.A',
    ISPB: '18520834',
  },
  {
    Banco: 128,
    BancoNome: 'MS BANK S.A BANCO DE CÂMBIO',
    ISPB: '19307785',
  },
  {
    Banco: 194,
    BancoNome: 'PARMETAL DTVM LTDA',
    ISPB: '20155248',
  },
  {
    Banco: 310,
    BancoNome: 'VORTX DTVM LTDA',
    ISPB: '22610500',
  },
  {
    Banco: 163,
    BancoNome: 'COMMERZBANK BRASIL S.A BANCO MÚLTIPLO',
    ISPB: '23522214',
  },
  {
    Banco: 280,
    BancoNome: 'AVISTA S.A',
    ISPB: '23862762',
  },
  {
    Banco: 146,
    BancoNome: 'GUITTA CC LTDA',
    ISPB: '24074692',
  },
  {
    Banco: 279,
    BancoNome: 'CCR DE PRIMAVERA DO LESTE',
    ISPB: '26563270',
  },
  {
    Banco: 182,
    BancoNome: 'DACASA FINANCEIRA S/A',
    ISPB: '27406222',
  },
  {
    Banco: 278,
    BancoNome: 'GENIAL INVESTIMENTOS CVM S.A',
    ISPB: '27652684',
  },
  {
    Banco: 271,
    BancoNome: 'IB CCTVM LTDA',
    ISPB: '27842177',
  },
  {
    Banco: 21,
    BancoNome: 'BANCO BANESTES S.A',
    ISPB: '28127603',
  },
  {
    Banco: 246,
    BancoNome: 'BANCO ABC BRASIL S.A',
    ISPB: '28195667',
  },
  {
    Banco: 751,
    BancoNome: 'SCOTIABANK BRASIL',
    ISPB: '29030467',
  },
  {
    Banco: 208,
    BancoNome: 'BANCO BTG PACTUAL S.A',
    ISPB: '30306294',
  },
  {
    Banco: 746,
    BancoNome: 'BANCO MODAL S.A',
    ISPB: '30723886',
  },
  {
    Banco: 241,
    BancoNome: 'BANCO CLASSICO S.A',
    ISPB: '31597552',
  },
  {
    Banco: 612,
    BancoNome: 'BANCO GUANABARA S.A',
    ISPB: '31880826',
  },
  {
    Banco: 604,
    BancoNome: 'BANCO INDUSTRIAL DO BRASIL S.A',
    ISPB: '31895683',
  },
  {
    Banco: 505,
    BancoNome: 'BANCO CREDIT SUISSE (BRL) S.A',
    ISPB: '32062580',
  },
  {
    Banco: 196,
    BancoNome: 'BANCO FAIR CC S.A',
    ISPB: '32648370',
  },
  {
    Banco: 300,
    BancoNome: 'BANCO LA NACION ARGENTINA',
    ISPB: '33042151',
  },
  {
    Banco: 477,
    BancoNome: 'CITIBANK N.A',
    ISPB: '33042953',
  },
  {
    Banco: 266,
    BancoNome: 'BANCO CEDULA S.A',
    ISPB: '33132044',
  },
  {
    Banco: 122,
    BancoNome: 'BANCO BRADESCO BERJ S.A',
    ISPB: '33147315',
  },
  {
    Banco: 376,
    BancoNome: 'BANCO J.P. MORGAN S.A',
    ISPB: '33172537',
  },
  {
    Banco: 473,
    BancoNome: 'BANCO CAIXA GERAL BRASIL S.A',
    ISPB: '33466988',
  },
  {
    Banco: 745,
    BancoNome: 'BANCO CITIBANK S.A',
    ISPB: '33479023',
  },
  {
    Banco: 120,
    BancoNome: 'BANCO RODOBENS S.A',
    ISPB: '33603457',
  },
  {
    Banco: 265,
    BancoNome: 'BANCO FATOR S.A',
    ISPB: '33644196',
  },
  {
    Banco: 7,
    BancoNome: 'BNDES (Banco Nacional do Desenvolvimento Social)',
    ISPB: '33657248',
  },
  {
    Banco: 188,
    BancoNome: 'ATIVA S.A INVESTIMENTOS',
    ISPB: '33775974',
  },
  {
    Banco: 134,
    BancoNome: 'BGC LIQUIDEZ DTVM LTDA',
    ISPB: '33862244',
  },
  {
    Banco: 641,
    BancoNome: 'BANCO ALVORADA S.A',
    ISPB: '33870163',
  },
  {
    Banco: 29,
    BancoNome: 'BANCO ITAÚ CONSIGNADO S.A',
    ISPB: '33885724',
  },
  {
    Banco: 243,
    BancoNome: 'BANCO MÁXIMA S.A',
    ISPB: '33923798',
  },
  {
    Banco: 78,
    BancoNome: 'HAITONG BI DO BRASIL S.A',
    ISPB: '34111187',
  },
  {
    Banco: 111,
    BancoNome: 'BANCO OLIVEIRA TRUST DTVM S.A',
    ISPB: '36113876',
  },
  {
    Banco: 17,
    BancoNome: 'BNY MELLON BANCO S.A',
    ISPB: '42272526',
  },
  {
    Banco: 174,
    BancoNome: 'PERNAMBUCANAS FINANC S.A',
    ISPB: '43180355',
  },
  {
    Banco: 495,
    BancoNome: 'LA PROVINCIA BUENOS AIRES BANCO',
    ISPB: '44189447',
  },
  {
    Banco: 125,
    BancoNome: 'BRASIL PLURAL S.A BANCO',
    ISPB: '45246410',
  },
  {
    Banco: 488,
    BancoNome: 'JPMORGAN CHASE BANK',
    ISPB: '46518205',
  },
  {
    Banco: 65,
    BancoNome: 'BANCO ANDBANK S.A',
    ISPB: '48795256',
  },
  {
    Banco: 492,
    BancoNome: 'ING BANK N.V',
    ISPB: '49336860',
  },
  {
    Banco: 250,
    BancoNome: 'BANCO BCV',
    ISPB: '50585090',
  },
  {
    Banco: 145,
    BancoNome: 'LEVYCAM CCV LTDA',
    ISPB: '50579044',
  },
  {
    Banco: 494,
    BancoNome: 'BANCO REP ORIENTAL URUGUAY',
    ISPB: '51938876',
  },
  {
    Banco: 253,
    BancoNome: 'BEXS CC S.A',
    ISPB: '52937216',
  },
  {
    Banco: 269,
    BancoNome: 'HSBC BANCO DE INVESTIMENTO',
    ISPB: '53518684',
  },
  {
    Banco: 213,
    BancoNome: 'BCO ARBI S.A',
    ISPB: '54403563',
  },
  {
    Banco: 139,
    BancoNome: 'INTESA SANPAOLO BRASIL S.A',
    ISPB: '55230916',
  },
  {
    Banco: 18,
    BancoNome: 'BANCO TRICURY S.A',
    ISPB: '57839805',
  },
  {
    Banco: 630,
    BancoNome: 'BANCO INTERCAP S.A',
    ISPB: '58497702',
  },
  {
    Banco: 224,
    BancoNome: 'BANCO FIBRA S.A',
    ISPB: '58616418',
  },
  {
    Banco: 600,
    BancoNome: 'BANCO LUSO BRASILEIRO S.A',
    ISPB: '59118133',
  },
  {
    Banco: 623,
    BancoNome: 'BANCO PAN',
    ISPB: '59285411',
  },
  {
    Banco: 204,
    BancoNome: 'BANCO BRADESCO CARTOES S.A',
    ISPB: '59438325',
  },
  {
    Banco: 479,
    BancoNome: 'BANCO ITAUBANK S.A',
    ISPB: '60394079',
  },
  {
    Banco: 456,
    BancoNome: 'BANCO MUFG BRASIL S.A',
    ISPB: '60498557',
  },
  {
    Banco: 464,
    BancoNome: 'BANCO SUMITOMO MITSUI BRASIL S.A',
    ISPB: '60518222',
  },
  {
    Banco: 613,
    BancoNome: 'OMNI BANCO S.A',
    ISPB: '60850229',
  },
  {
    Banco: 652,
    BancoNome: 'ITAÚ UNIBANCO HOLDING BM S.A',
    ISPB: '60872504',
  },
  {
    Banco: 653,
    BancoNome: 'BANCO INDUSVAL S.A',
    ISPB: '61024352',
  },
  {
    Banco: 69,
    BancoNome: 'BANCO CREFISA S.A',
    ISPB: '61033106',
  },
  {
    Banco: 370,
    BancoNome: 'BANCO MIZUHO S.A',
    ISPB: '61088183',
  },
  {
    Banco: 249,
    BancoNome: 'BANCO INVESTCRED UNIBANCO S.A',
    ISPB: '61182408',
  },
  {
    Banco: 318,
    BancoNome: 'BANCO BMG S.A',
    ISPB: '61186680',
  },
  {
    Banco: 626,
    BancoNome: 'BANCO FICSA S.A',
    ISPB: '61348538',
  },
  {
    Banco: 270,
    BancoNome: 'SAGITUR CC LTDA',
    ISPB: '61444949',
  },
  {
    Banco: 366,
    BancoNome: 'BANCO SOCIETE GENERALE BRASIL',
    ISPB: '61533584',
  },
  {
    Banco: 113,
    BancoNome: 'MAGLIANO S.A',
    ISPB: '61723847',
  },
  {
    Banco: 131,
    BancoNome: 'TULLETT PREBON BRASIL CVC LTDA',
    ISPB: '61747085',
  },
  {
    Banco: 11,
    BancoNome: 'C.SUISSE HEDGING-GRIFFO CV S.A (Credit Suisse)',
    ISPB: '61809182',
  },
  {
    Banco: 611,
    BancoNome: 'BANCO PAULISTA',
    ISPB: '61820817',
  },
  {
    Banco: 755,
    BancoNome: 'BOFA MERRILL LYNCH BM S.A',
    ISPB: '62073200',
  },
  {
    Banco: 89,
    BancoNome: 'CCR REG MOGIANA',
    ISPB: '62109566',
  },
  {
    Banco: 643,
    BancoNome: 'BANCO PINE S.A',
    ISPB: '62144175',
  },
  {
    Banco: 140,
    BancoNome: 'EASYNVEST – TÍTULO CV S.A',
    ISPB: '62169875',
  },
  {
    Banco: 707,
    BancoNome: 'BANCO DAYCOVAL S.A',
    ISPB: '62232889',
  },
  {
    Banco: 288,
    BancoNome: 'CAROL DTVM LTDA',
    ISPB: '62237649',
  },
  {
    Banco: 101,
    BancoNome: 'RENASCENCA DTVM LTDA',
    ISPB: '62287735',
  },
  {
    Banco: 487,
    BancoNome: 'DEUTSCHE BANK S.A BANCO ALEMÃO',
    ISPB: '62331228',
  },
  {
    Banco: 233,
    BancoNome: 'BANCO CIFRA',
    ISPB: '62421979',
  },
  {
    Banco: 177,
    BancoNome: 'GUIDE',
    ISPB: '65913436',
  },
  {
    Banco: 633,
    BancoNome: 'BANCO RENDIMENTO S.A',
    ISPB: '68900810',
  },
  {
    Banco: 218,
    BancoNome: 'BANCO BS2 S.A',
    ISPB: '71027866',
  },
  {
    Banco: 292,
    BancoNome: 'BS2 DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS',
    ISPB: '28650236',
  },
  {
    Banco: 169,
    BancoNome: 'BANCO OLÉ BONSUCESSO CONSIGNADO S.A',
    ISPB: '71371686',
  },
  {
    Banco: 293,
    BancoNome: 'LASTRO RDV DTVM LTDA',
    ISPB: '71590442',
  },
  {
    Banco: 285,
    BancoNome: 'FRENTE CC LTDA',
    ISPB: '71677850',
  },
  {
    Banco: 80,
    BancoNome: 'B&T CC LTDA',
    ISPB: '73622748',
  },
  {
    Banco: 753,
    BancoNome: 'NOVO BANCO CONTINENTAL S.A BM',
    ISPB: '74828799',
  },
  {
    Banco: 222,
    BancoNome: 'BANCO CRÉDIT AGRICOLE BR S.A',
    ISPB: '75647891',
  },
  {
    Banco: 754,
    BancoNome: 'BANCO SISTEMA',
    ISPB: '76543115',
  },
  {
    Banco: 98,
    BancoNome: 'CREDIALIANÇA CCR',
    ISPB: '78157146',
  },
  {
    Banco: 610,
    BancoNome: 'BANCO VR S.A',
    ISPB: '78626983',
  },
  {
    Banco: 712,
    BancoNome: 'BANCO OURINVEST S.A',
    ISPB: '78632767',
  },
  {
    Banco: 10,
    BancoNome: 'CREDICOAMO',
    ISPB: '81723108',
  },
  {
    Banco: 283,
    BancoNome: 'RB CAPITAL INVESTIMENTOS DTVM LTDA',
    ISPB: '89960090',
  },
  {
    Banco: 217,
    BancoNome: 'BANCO JOHN DEERE S.A',
    ISPB: '91884981',
  },
  {
    Banco: 117,
    BancoNome: 'ADVANCED CC LTDA',
    ISPB: '92856905',
  },
  {
    Banco: 336,
    BancoNome: 'BANCO C6 S.A – C6 BANK',
    ISPB: '31872495',
  },
  {
    Banco: 654,
    BancoNome: 'BANCO DIGIMAIS S.A',
    ISPB: '92874270',
  },
  {
    Banco: 380,
    BancoNome: 'PicPay Servicos S.A.',
    ISPB: '22896431',
  },
  {
    Banco: 413,
    BancoNome: 'Banco BV',
    ISPB: '01858774',
  },
  {
    Banco: 244,
    BancoNome: 'NEON PAGAMENTOS S.A',
    ISPB: '20855875',
  },
  {
    Banco: 403,
    BancoNome: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    ISPB: '37880206',
  },
];

export default banksContant;
