import fetch from '@giro/shared-utils/fetch.util';

export type BodyType = {
  name: string;
  apps: Array<'cartao' | 'pix' | 'boleto'>;
};

export default async function postApiUserkeysService(body: any) {
  return fetch('userkeys', {
    method: 'POST',
    ms: 'PDV',
    auth: true,
    body: JSON.stringify(body),
  });
}
